<template>
    <div class="hero-area">
       <div class="container">
          <div class="row">

            <div v-for="(item, index) in projectList" :key="item.id" :class="index==0?' col-12 col-md-4 single-block':'col-6 col-md-4 single-block'" @click="goToProject(item.id);" style="cursor:pointer;">
                <picture>
                   <source media="(min-width:650px)" :srcset="item.project_image">
                   <source media="(min-width:465px)" :srcset="item.project_image">
                   <img :src="item.project_image" :alt="item.name" style="width:100%;">
                </picture>
                <div class="single-content">

                  <p class="hero-single-content-heading" > 
                    {{ item.project_keywords }} 
                  </p>

                  <p class="hero-single-content-text" v-if="item.name.length<60" > 
                    {{ item.name }} 
                  </p>
                  <p class="hero-single-content-text" v-else > 
                    {{ item.name.substring(0,60)+".." }}
                  </p>

                  <router-link :to="{name: 'Donate', params:{id:item.id}}" class="btn hero-donate-btn">DONATE</router-link>
                </div>
             </div>

             <div class="col-md-4 hero-story">
                <p class="hero-story-title"> TEAM BIDYANONDO</p>
                <p class="hero-story-content"> 
                  A mad passionate group of people working to inspire one nation at a time Bidyanondo (Learn for Fun) is an educational voluntary organization originating from Bangladesh.
                </p>
                <router-link :to="{ name: 'ExploreProject'}">
                  <p class="hero-story-btn">
                    EXPLORE PROJECTS
                  </p>
                </router-link>
             </div>
          </div>
       </div>
    </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  //import axios from 'axios'
  //const apiResourceUrl = process.env.VUE_APP_APIURL;
  export default {
    name: 'HeroAreaProject',
    data(){
      return{
        //projectList : {}
      }
    },
    methods: {
      goToProject(project_id){
        this.$router.push({ name: 'Donate', params: {id: project_id}});
      }
    },
    computed: {
      ...mapGetters({
        projectList : 'homeHighlightedProjectData'
      })
    },
    async created(){
      // await axios.get(apiResourceUrl+'/projects').then((response) => {
      //   this.projectList = (response.data.projects).slice(0, 5);
      // })
    },
    mounted(){
    
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
