<template>
  <div>

    <!--body hero section start -->
    <div class="campaign_hero_area">
        <div class="container">
            <div class="row">
               <img :src="campaign.cover_image" :alt="campaign.title">
            </div>
        </div>
    </div>

    <!--body section start -->
 
    <div class="container">
        <div class="campaign">
            <div class="row">
                <div class="col-12">
                    <h1>{{campaign.title}}</h1>
                    <p>
                        {{campaign.description}}
                    </p>
                </div>   

                <div class="col-12 middle_section">
                    <div class="row">
                        <div class="col-4" v-for="list in campaignImages" :key="list.id">
                            <img :src="list.image" alt="">
                        </div>
                    </div>
                </div> 
                                          
            </div> 
        </div>      
    </div>

    <!--body section end -->

  </div>
</template>

<script>
  import axios from 'axios'
  const apiResourceUrl = process.env.VUE_APP_APIURL;
  export default {
    name: 'CampaignDetails',
    data(){
      return{
        campaign : {},
        campaignImages : {}
      }
    },
    async created(){
        var id = this.$route.params.id;
        await axios.get(apiResourceUrl+'/campaign/' + id).then((response) => {
            this.campaign = response.data.campaign;
            this.campaignImages = response.data.campaignImages;
            console.log(response.data.campaignImages);
        })
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
