<template>
  <div>
    <div class="page_title_bg">
        <div class="container">
        <div class="row">
            <div class="col-xl-12 col-xs-12 donation_clearification_header text-center">
                <p class="donation_clearification_title"><span>GALLERY MEDIA ALBUM</span>  </p>
        
                <p class="donation_clearification_text">Home / Media / Press</p>
            </div>
        </div>
        </div>
    </div>

    <div class="container">
        <div class="row">
            <div class="col-xl-12 press-table-center">
                <div class="press_submenu myBtnContainer">
                    <ul class="filter-button-group text-center">
                        
                        <li class="press_submenu_items">
                            <button  @click="filterData('all')" :class="(activeCategory=='all')?'activecategory':''">all</button>
                        </li>

                        <li v-for="item in pressCategoryList" :key="item.id" class="press_submenu_items">
                            <button  @click="filterData(item.id)" :class="(activeCategory==item.id)?'activecategory':''">
                                <!-- <span class="press_submenu_bullet">.</span>  -->
                                {{item.name}} 
                            </button>
                        </li>

                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="row grid" v-if="loadedPaginatedData.length > 0">
            <div class="col-xl-4 col-xs-12 col-sm-6 show column" v-for="list in loadedPaginatedData" :key="list.id">
                <div class="card text-center press_card_items">
                   <img :src="(list.image)?list.image:'/assets/images/press/press_card_1.webp'" alt="" style="height: 100%">
                    <div class="press_card_item_info_area">
                        <div class="card-body press_card_item_info">
                            <div class="press_card_item_date">
                                <ul>
                                    <li><img src="/assets/images/icon/calendar.png" alt="calender"></li>
                                    <li>{{list.published_date}}</li>
                                </ul>
                            </div>
                            <div class="press_card_item_description" v-if="list.description.length > 32">
                                {{ list.description.substring(0,32)+" . ." }}
                            </div>
                            <div class="press_card_item_description" v-else>
                                {{ list.description }}
                            </div>
                            <a :href="list.press_link" target="_blank" class="btn press_btn">Read More</a>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Pagination Start  -->
            <div class="col-12" v-if="totalPage > 1">
              <div class="page-list">
                <ul>

                  <li class="page-no"><a href="javascript:;" @click="getData(1)">
                      <font-awesome-icon icon="angle-double-left" />
                  </a></li>

                  <li class="page-no" v-if="prev_page_url"><a href="javascript:;" @click="getData(prevPage)">
                    <font-awesome-icon icon="angle-left" />
                  </a></li>
                  <li class="page-no" v-else><a href="javascript:;" style="cursor: not-allowed;">
                    <font-awesome-icon icon="angle-left" />
                  </a></li>
                  
                  <li v-for="(pageNum) in totalPage" :key="pageNum" :class="(pageNum > displayStartPageNumber && pageNum < displayEndPageNumber)?'page-no':'page-no hidePaginationNumber'">
                    <a href="javascript:;" @click="getData(pageNum)"  :class="current_page==pageNum?'active-page':''">
                      {{ pageNum }}
                    </a>
                  </li>

                  <li class="page-no" v-if="next_page_url"><a href="javascript:;" @click="getData(nextPage)">
                    <font-awesome-icon icon="angle-right" />
                  </a></li>
                  <li class="page-no" v-else><a href="javascript:;" style="cursor: not-allowed;">
                    <font-awesome-icon icon="angle-right" />
                  </a></li>

                  <li class="page-no"><a href="javascript:;" @click="getData(totalPage)">
                      <font-awesome-icon icon="angle-double-right" />
                  </a></li>

                </ul>
              </div>
            </div>
            <!-- Pagination End -->
            <div class="col-12">
                <br><br>
            </div>
            
        </div>
        <div class="row grid" v-else>
            <div class="col-xl-12 col-xs-12 col-sm-12 show column emptymessages">
                <h3>Currently There Are No Media Available.</h3>
                <br><br><br>
            </div>
        </div>
    </div>
    
  </div>
</template>

<script>
  import axios from 'axios'
  const apiResourceUrl = process.env.VUE_APP_APIURL;
  export default {
    name: 'Media',
    data(){
      return{
        pressCategoryList : {},
        pressList : {},
        dynamicPressList : {},
        activeCategory : 'all',

        perPageData : 9,
        paginationPageBtnLength : 5,  
        current_page : null,
        prev_page_url : false,
        next_page_url : false,
        totalPage : null,
        prevPage : null,
        nextPage : null,
        loadedPaginatedData : {},
        displayStartPageNumber : null,
        displayEndPageNumber : null,
      }
    },
    
    methods: {
        filterData(id)
        {   
            //console.log(id)
            this.activeCategory = id;
            this.dynamicPressList = [];
            this.loadedPaginatedData = [];

            if(id=='all'){
                this.dynamicPressList = this.pressList;
            }
            else{
                for (var prop in this.pressList) {
                    //console.log(this.pressList[prop]['press_category_id'])
                    if(this.pressList[prop]['press_category_id']==id){
                       this.dynamicPressList.push(this.pressList[prop]) 
                    }
                }
            }

            this.initPagination();
            //console.log(this.dynamicPressList);
        },

        initPagination(){

            if(this.dynamicPressList.length > 0){

                //Count Total page Start
                let totPage = parseInt(this.dynamicPressList.length) / parseInt(this.perPageData);
                if((parseInt(this.dynamicPressList.length) % parseInt(this.perPageData)) != 0){
                    totPage = parseInt(totPage) +1;
                    this.totalPage = totPage
                }
                //Count Total page End  

                this.current_page = 1;
                this.displayStartPageNumber = 0;
                this.displayEndPageNumber = 1 + this.paginationPageBtnLength;

                this.getData(1);
            }
        },

        paginationClientMenuOrganise(){
            this.prevPage = null
            this.nextPage = null

            if(this.totalPage==1){
              this.next_page_url = false;
              this.prev_page_url = false;
            }else{
              this.nextPage = this.current_page + 1;
              this.prevPage = this.current_page - 1;
            }

            if(this.current_page == this.totalPage){
              this.next_page_url = false;
            }

            if(this.current_page == 1){
              this.prev_page_url = false;
            }

            let interval = parseInt(this.paginationPageBtnLength / 2);
            if(this.totalPage <= this.paginationPageBtnLength){
                this.displayStartPageNumber = 0;
                this.displayEndPageNumber = this.paginationPageBtnLength +1;
            }else{
                if((this.current_page - interval) <= 1){
                    this.displayStartPageNumber = 0;
                    this.displayEndPageNumber = this.paginationPageBtnLength +1;
                }
                else if((this.current_page + interval) >= this.totalPage){
                    this.displayStartPageNumber = this.totalPage - this.paginationPageBtnLength;
                    this.displayEndPageNumber = this.totalPage +1;
                }else{
                    this.displayStartPageNumber = this.current_page - interval - 1;
                    this.displayEndPageNumber = this.current_page + interval +1;
                }
            }
        },

        getData(pageNum){
            let toNumber = pageNum*this.perPageData;
            let fromNumber = toNumber - this.perPageData;
            //console.log(this.dynamicPressList.slice(fromNumber, toNumber));
            this.loadedPaginatedData = this.dynamicPressList.slice(fromNumber, toNumber);
            this.current_page = pageNum;

            this.next_page_url = false; 
            this.prev_page_url = false;
            if(pageNum < this.totalPage){
                this.next_page_url = true; 
            }

            if(pageNum > 1){
                this.prev_page_url = true; 
            }            
            this.paginationClientMenuOrganise();
        }
    },

    computed: {
      
    },
    async created(){
        await axios.get(apiResourceUrl+'/media').then((response) => {
            this.pressCategoryList = response.data.press_categories;
            this.pressList = response.data.presses;
            this.dynamicPressList = response.data.presses;
            //console.log(response.data.press_categories);
            //console.log(response.data.presses);

            this.initPagination();
        })
    },
    mounted(){

    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
