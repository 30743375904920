<template>
    <div class="container join-us-section">
       <div class="row">
          <div class="col-md-12">
             <p class="text-center join-now-heading">JOIN US</p>
          </div>
       </div>
       <div class="row">
          <div class="col-md-4 join-single-block text-center">
            <router-link to="/donate">
             <div class="join-single-image m-auto">
                <img src="/assets/images/icon/hand.webp" alt="DONATIONS">
             </div>
             <p class="join-single-heading"> DONATIONS</p>
            </router-link>
             <p class="join-single-text"> Bidyanondo is always with you at any crisis in
                Bangladesh to help and support you.
             </p>
          </div>
          <div class="col-md-4 join-single-block text-center">
            <router-link to="/bangladeshi-volunteer">
             <div class="join-single-image m-auto">
                <img src="/assets/images/icon/volunteer.webp" alt="DONATIONS">
             </div>
             <p class="join-single-heading"> VOLUNTEER</p>
            </router-link>
             <p class="join-single-text"> Bidyanondo will be proud to have you as a volunteer.</p>
          </div>
          <div class="col-md-4 join-single-block text-center">
            <router-link to="/sponsor">
             <div class="join-single-image m-auto">
                <img src="/assets/images/icon/sopnsor.webp" alt="DONATIONS">
             </div>
             <p class="join-single-heading"> SPONSORSHIP</p>
            </router-link>
             <p class="join-single-text"> Support us by your donations to create a better future.</p>
          </div>
       </div>
    </div> 
</template>

<script>
  export default {
    name: 'HomeJoinUs'
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
