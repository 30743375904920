<template>
  <div>
    <!--body section start -->
    <div class="sponsor_hero_area custom_page_title_bg">
        <div class="container">
            <div class="row">
                <h1 class="text-center">Volunteer Registration</h1><br>
                <hr class="line">
                <p class="text-center">Home / Volunteer Registration</p>
            </div>
        </div>
    </div>

    <!-- Registration Form start -->
    <div id="body-section">
        <div class="container">
            <div class="row">
                <div class="select_area  _international">
                    <router-link class="bangladesh" to="/bangladeshi-volunteer">For Bangladeshi Volunteers</router-link>
                    <router-link class="international international international_shadow" to="/international-volunteer">For International Volunteers</router-link>
                </div>
            </div>
            <div class="registration-form">                
                <form @submit="formSubmit" enctype="multipart/form-data">
                    
                    <div class="row">
                        <div class="col-md-6">
                            <label for="first_name">First Name*</label>
                            <input type="text" id="first_name" name="first_name" placeholder="Enter Your First Name" v-model="first_name" required>
                        </div>

                        <div class="col-md-6">
                            <label for="first_name">Last Name*</label>
                            <input type="text" id="last_name" name="last_name" placeholder="Enter Your Last Name" v-model="last_name" required>
                        </div>

                        <div class="col-md-6">
                            <label for="email">Email *</label>
                            <input type="text" id="email" name="email" placeholder="Enter Your Email" v-model="email" required>
                        </div>

                        <div class="col-md-6">  
                            <label for="contact_no">Contact no *</label>
                            <input type="text" id="contact_no" name="contact_no" placeholder="Enter Contact Number" v-model="contact_no" required>
                        </div>

                        <div class="col-md-6">
                            <label for="gender_id">Gender *</label>
                            <select name="gender_id" id="gender_id" v-model="gender_id" required>
                                <option v-for="gender_data in genders" :key="gender_data.id" :value="gender_data.id">
                                    {{gender_data.gender_name}}
                                </option>
                            </select>
                        </div>

                        <div class="col-md-6">  
                            <label for="nationality">Nationality *</label>
                            <input type="text" id="nationality" name="nationality" placeholder="Enter Country Name" v-model="nationality" required>
                        </div>

                        <div class="col-md-6">  
                            <label for="interest">Your Interest *</label>
                            <input class="wide_input" type="text" id="interest" name="interest" placeholder="Enter Your Interest" v-model="interest" required>
                        </div>

                        <div class="col-md-6">  
                            <label for="address">Your Address *</label>
                            <input class="wide_input" type="text" id="address" name="address" placeholder="Enter Your Address" v-model="address">
                        </div>
                        
                        <div class="col-md-6">  
                            <label for="emergency_contact_details">emergenct conatct details *</label>
                            <input class="wide_input" type="text" id="emergency_contact_details" name="emergency_contact_details" placeholder="Enter Emergenct Conatct Details" v-model="emergency_contact_details" required>
                        </div>

                        <div class="col-md-6">  
                            <label for="biography">biography *</label>
                            <input class="wide_input" type="text" id="biography" name="biography" placeholder="Enter Your Biography" v-model="biography" required>
                        </div>

                        <div class="col-md-6">  
                            <label for="start_date">Volunteerity Start date *</label>
                            <input type="date" id="start_date" name="start_date" placeholder="DD/MM/YYYY" v-model="start_date" required>
                        </div>

                        <div class="col-md-6">  
                            <label for="end_date">Volunteerity end date *</label>
                            <input type="date" id="end_date" name="end_date" placeholder="DD/MM/YYYY" v-model="end_date">
                        </div>
                                            
                        <div class="col-md-12">  
                            <label for="passport_no">passport no *</label>
                            <input type="text" id="passport_no" name="passport_no" placeholder="Enter Your Passport No" v-model="passport_no">
                        </div>

                        <div class="col-md-6 image">
                            <label for="profile_image">Your Picture *</label>
                            <input type="file" id="profile_image" name="profile_image" ref="file" @change="onFileSelected">
                            <p class="image-size">(recommended image size 160x160)</p>
                        </div>    
                        <div class="col-md-6 image">
                            <label for="pasport_image">passport image (sacn copy) *</label>
                            <input type="file" id="pasport_image" name="pasport_image" ref="file2" @change="onFileSelected">
                        </div>            
                    </div>
                    
                    <div class="row">
                        <div class="submit-area">
                            <input type="submit" value="SEND" class="btn signIn_btn contactbtn">
                        </div>
                    </div>
                </form>
            </div>            
        </div>
    </div>

    <!--Registration Form end -->
  </div>
</template>

<script>
  import axios from 'axios'
  import swal from 'sweetalert';
  const apiResourceUrl = process.env.VUE_APP_APIURL;
  export default {
    name: 'InternationalVolunteer',
    data(){
      return{
        genders : {},
        gender_id:'',
        first_name:'',
        last_name:'',
        email:'',
        contact_no:'',
        profile_image:'',
        pasport_image:'',
        nationality : '',
        interest : '',
        address : '',
        emergency_contact_details : '',
        biography : '',
        start_date : '',
        end_date : '',
        passport_no : '',
      }
    },
    methods: {

        formSubmit(e) {

            e.preventDefault();
            var formObject = this;

            const form = new FormData()
            form.append('gender_id', this.gender_id)
            form.append('first_name', this.first_name)
            form.append('last_name', this.last_name)
            form.append('email', this.email)
            form.append('contact_no', this.contact_no) 
            form.append('profile_image', this.profile_image) 
            form.append('pasport_image', this.pasport_image) 
            form.append('nationality', this.nationality) 
            form.append('interest', this.interest) 
            form.append('address', this.address) 
            form.append('emergency_contact_details', this.emergency_contact_details) 
            form.append('biography', this.biography) 
            form.append('start_date', this.start_date) 
            form.append('end_date', this.end_date) 
            form.append('passport_no', this.passport_no) 
            form.append('volunteer', 2) 

            var config = {
              headers: { 'Content-Type': 'multipart/form-data' }
            }

            axios.post(apiResourceUrl+'/save-volunteer-registration', form, config)
            .then(function (response) {
                //console.log(response.data.message);
                swal(response.data.message, '', "success");
                formObject.resetForm();
            })
            .catch(function (error) {
                let alertmessage = "";
                for (var prop in error.response.data) {
                    alertmessage += ' '+error.response.data[prop]
                }
                //console.log(alertmessage)
                //alert(alertmessage)
                swal(alertmessage, '', "error");

            });
        },

        resetForm(){

            this.first_name = '';
            this.last_name = '';
            this.email = '';
            this.contact_no = '';
            this.nationality = '';
            this.interest = '';
            this.address = '';
            this.emergency_contact_details = '';
            this.biography = '';
            this.start_date = '';
            this.end_date = '';
            this.passport_no = '';
            this.gender_id = this.genders[0]['id']

            const input = this.$refs.file;
            input.type = 'text';
            input.type = 'file';

            const input2 = this.$refs.file2;
            input2.type = 'text';
            input2.type = 'file';

        },

        onFileSelected(event){
            if(event.target.id=="pasport_image"){
                this.pasport_image = event.target.files[0]
            }

            if(event.target.id=="profile_image"){
                this.profile_image = event.target.files[0]
            }
            //this.profile_image = event.target.files[0]
        },
    },
    async created(){
      await axios.get(apiResourceUrl+'/get-volunteer-form-data').then((response) => {
        this.genders = response.data.result.genders;
        this.gender_id = response.data.result.genders[0]['id']

        //console.log(response.data.result.contacts);
      })
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
