<template>
  <div>

    <div class="page_title_bg">
        <div class="container">
        <div class="row">
            <div class="col-xl-12 col-xs-12 donation_clearification_header text-center">
                <p class="donation_clearification_title">contact us</p>
                <p class="white-bar"></p>
                <p class="donation_clearification_text">Home / Contact Us</p>
            </div>
        </div>
        </div>
    </div>

    <div class="contact_body_bg">
        <div class="container">
            <div class="row">
                <div class="col-xl-12 col-12 text-center">
                    <div class="leave_message_title">
                        LEAVE US A MESSAGE
                        <p class="grey-bar"></p>
                    </div>
                </div>
              
                <div class="col-xl-4 col-12 mb-5">
                    <div class="address_bg">
                        <table class="contact_us_address_area">
                            <tr>
                                <td class="contact_address_icon"><img src="/assets/images/icon/contact_address.webp" alt="address"></td>
                                <td class="contact_address_title">Location:</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td class="contact_address">{{settingsInfo.contact_address}}</td>
                            </tr>
                            <tr>
                                <td class="contact_address_icon"><img src="/assets/images/icon/contact_telephone.webp" alt="address"></td>
                                <td class="contact_address_title">Call Us:</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td class="contact_address">{{settingsInfo.contact_no}}</td>
                            </tr>
                        </table>
                    </div>
                </div>

                <div class="col-xl-8 col-12 text-center">
                    <form @submit="formSubmit" enctype="multipart/form-data">
                        <div class="form-row">
                            <div class="form-group col-md-6">
                              <input type="text" class="form-control contact_form_bg" placeholder="Name" v-model="name" required>
                            </div>
                            <div class="form-group col-md-6">
                              <input type="text" class="form-control contact_form_bg" placeholder="Email" v-model="email" required>
                            </div>
                        </div>

                        <div class="form-group">
                            <input type="text" class="form-control contact_form_bg" placeholder="Subject" v-model="subject" required>
                        </div>
                        <div class="form-group">
                            <textarea class="form-control contact_form_bg" rows="5" placeholder="Your Message" v-model="message" required></textarea>
                        </div>
    
                        <div class="col-xl-12 text-center d-flex justify-content-center">
                            <button class="btn signIn_btn contactbtn">SEND MESSAGE</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

  </div>
</template>

<script>
    import axios from 'axios'
    const apiResourceUrl = process.env.VUE_APP_APIURL;
    export default {
      name: 'Contact',
      data(){
         return{
            settingsInfo : {},
            name : '',
            email : '',
            subject : '',
            message : ''
         }
      },
      computed: {
      
      },
      methods:{
        formSubmit(e) {

            e.preventDefault();
            var formObject = this;
            //console.log(formObject);
            const form = new FormData()
            form.append('name', this.name)
            form.append( 'email', this.email)
            form.append('subject', this.subject)
            form.append('message', this.message)

            var config = {
              headers: { 'Content-Type': 'multipart/form-data' }
            }

            axios.post(apiResourceUrl+'/send-message', form, config)
            .then(function (response) {
                //console.log(response.data.message);
                alert(response.data.message)
                formObject.resetForm();
            })
            .catch(function (error) {
                let alertmessage = "";
                for (var prop in error.response.data) {
                    alertmessage += error.response.data[prop]
                }
                //console.log(alertmessage)
                alert(alertmessage)
            });
        },

        resetForm(){
            this.name = '';
            this.email = '';
            this.subject = '';
            this.message = '';
        }

      },
      created(){
        axios.get(apiResourceUrl+'/websitesettings').then((response) => {
            this.settingsInfo = (response.data.settings);
            //console.log(response.data.settings);
        })
      },
      mounted(){
        
      }
    }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
