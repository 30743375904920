<template>
  <div id="body-section">
    <div class="container">
      <div class="row doner-profile">
        <div class="col-md-3">
          <div class="profile-left">
            <router-link :to="{ name: 'Donor' }" class="yellow-btn">
              Home
            </router-link>
            <router-link :to="{ name: 'DonorProfile' }" class="yellow-btn">
              Doner profile
            </router-link>

            <router-link :to="{ name: 'DonorScholarship' }" class="green-btn">
              Students
            </router-link>
            <router-link :to="{ name: 'SpecialMealReport' }" class="yellow-btn">
              Special Meals
            </router-link>
          </div>
        </div>
        <div class="col-md-9 student">
          <div class="student-title-bar">
            <div class="row">
              <p>
                <img src="/assets/images/icon/stuent.png" alt="" /> Student List
              </p>
            </div>
          </div>

          <div class="profile-right" v-for="list in studentList" :key="list.id">
            <div class="row d-flex justify-content-between">
              <div class="left d-flex">
                <img :src="list.student_image" :alt="list.name" />
                <p>{{ list.name }}</p>
              </div>
              <div class="right">
                <router-link
                  :to="{ name: 'SponsorNow', params: { id: list.id } }"
                  class="yellow-btn"
                  >Sponsor</router-link
                >
                <router-link
                  :to="{
                    name: 'DonorScholarshipDetails',
                    params: {
                      id: list.id,
                      scholarship_id: list.scholarship_id,
                    },
                  }"
                  class="green-btn"
                >
                  Details
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
const apiResourceUrl = process.env.VUE_APP_APIURL;

export default {
  name: "DonorScholarship",
  data() {
    return {
      studentList: "",
    };
  },

  async created() {
    var newObject = this;
    await axios
      .get(apiResourceUrl + "/donors_scholarship")
      .then(function(response) {
        console.log(response.data);
        newObject.studentList = response.data.students;
      })
      .catch(function(error) {
        let alertmessage = "";
        for (var prop in error.response.data) {
          alertmessage += " " + error.response.data[prop];
        }
        console.log(alertmessage);
      });
  },

  methods: {},

  computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
