<template>
  <div id="footerdiv">
    <!--footer section start-->
    <footer id="footer-menu">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <p class="footer-single-title">VISIT</p>
            <p class="footer-bar"></p>
            <div class="footer-sub-content">
              <ul>
                <li>
                  <router-link :to="{ name: 'ExploreProject' }"
                    >Projects</router-link
                  >
                </li>
                <li><router-link to="/sponsor">Sponsors</router-link></li>
                <li><router-link to="/media">Media</router-link></li>
                <li><router-link to="/gallery">Gallery</router-link></li>
                <li><router-link to="/video">Video</router-link></li>
                <li>
                  <a
                    href="https://onetakameal.org"
                    target="_blank"
                    style="color:#dd8e0a"
                    >One Taka Meal</a
                  >
                </li>
                <li>
                  <router-link
                    :to="{ name: 'DonationClarification' }"
                    style="color:#dd8e0a"
                    >Donation Clarification</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-3">
            <p class="footer-single-title">HELP</p>
            <p class="footer-bar"></p>
            <div class="footer-sub-content">
              <ul>
                <li><router-link to="/faq">FAQ</router-link></li>
                <li><router-link to="/contact">Contact us</router-link></li>
                <li><router-link to="/about">About Us</router-link></li>
                <li>
                  <router-link :to="{ name: 'Campaign' }">Campaign</router-link>
                </li>
                <li>
                  <router-link to="/bangladeshi-volunteer"
                    >Volunteers</router-link
                  >
                </li>
                <li>
                  <router-link to="/audit-report">Audit Report</router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'BankInfo' }">Banks</router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-3">
            <p class="footer-single-title">CONTACT</p>
            <p class="footer-bar"></p>
            <div class="contact footer-sub-content">
              <ul>
                <li>
                  <img
                    src="/assets/images/icon/location.webp"
                    alt="location icon"
                  />
                  &nbsp; {{ settingsInfo.contact_address }}
                </li>

                <li>
                  <img src="/assets/images/icon/call.webp" alt="mobile icon" />
                  &nbsp; {{ settingsInfo.contact_no }}
                </li>

                <li>
                  <img
                    src="/assets/images/icon/location.webp"
                    alt="location icon"
                  />
                  &nbsp; <router-link to="/branch">Other Branches</router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-3">
            <p class="footer-single-title">FOLLOW US ON</p>
            <p class="footer-bar"></p>
            <div class="footer-social-icon">
              <ul>
                <li>
                  <a :href="settingsInfo.facebook_social_link" target="_blank"
                    ><i class="fab fa-facebook-f"></i
                  ></a>
                </li>
                <li>
                  <a :href="settingsInfo.instagram_social_link" target="_blank"
                    ><i class="fab fa-instagram"></i
                  ></a>
                </li>
                <li>
                  <a :href="settingsInfo.twitter_social_link" target="_blank"
                    ><i class="fab fa-twitter"></i
                  ></a>
                </li>
                <li>
                  <a :href="settingsInfo.linkdin_social_link" target="_blank"
                    ><i class="fab fa-linkedin-in"></i
                  ></a>
                </li>
                <li>
                  <a :href="settingsInfo.goole_plus_social_link" target="_blank"
                    ><i class="fab fa-youtube"></i
                  ></a>
                </li>
              </ul>

              <a
                href="//www.dmca.com/Protection/Status.aspx?ID=aa8cc16b-ce55-44e6-af1c-6772e7021d4d"
                title="DMCA.com Protection Status"
                class="dmca-badge"
              >
                <img
                  src="https://images.dmca.com/Badges/dmca-badge-w150-5x1-11.png?ID=aa8cc16b-ce55-44e6-af1c-6772e7021d4d"
                  alt="DMCA.com Protection Status"
              /></a>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!--footer section end-->
    <!--Copyright section start -->
    <div class="copyright-section">
      <div class="container">
        <div class="row">
          <div class="col-md-12 copright-text">
            <p class="footer-copyright-text">
              <!-- <span class="copyright-bar"> </span> -->
              {{ new Date().getFullYear() }} © Bidyanondo Foundation | Developed

              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="heart"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                class="svg-inline--fa fa-heart fa-w-12 footerheart"
              >
                <path
                  fill="#e41f28"
                  d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"
                  class=""
                ></path>
              </svg>

              By <a href="https://www.kolpolok.com">Kolpolok Limited</a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <!--Copyright section end -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
//import axios from 'axios'
//const apiResourceUrl = process.env.VUE_APP_APIURL;
export default {
  name: "AppFooter",
  data() {
    return {
      //settingsInfo : {}
    };
  },
  computed: {
    ...mapGetters({
      settingsInfo: "settings",
    }),
  },
  created() {
    // axios.get(apiResourceUrl+'/websitesettings').then((response) => {
    //     this.settingsInfo = (response.data.settings);
    // })
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
