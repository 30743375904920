<template>
  <div>
    <!--body section start -->
    <div id="body-section" class="doner-home">
      <div class="container">
        <div class="row doner-profile">
          <div class="col-md-3">
            <div class="profile-left">
              <router-link :to="{ name: 'Donor' }" class="green-btn">
                Home
              </router-link>
              <router-link :to="{ name: 'DonorProfile' }" class="yellow-btn">
                Doner profile
              </router-link>
              <router-link
                :to="{ name: 'DonorScholarship' }"
                class="yellow-btn"
              >
                Students
              </router-link>
              <router-link
                :to="{ name: 'SpecialMealReport' }"
                class="yellow-btn"
              >
                Special Meals
              </router-link>
            </div>
          </div>
          <div class="col-md-9">
            <div class="title-bar">
              <div class="row">
                <div class="title">
                  <router-link :to="{ name: 'DonationClarification' }">
                    <img src="/assets/images/icon/plus.webp" alt="" /> New
                    donation Clerification
                  </router-link>
                </div>
              </div>
            </div>
            <div class="profile-right">
              <div class="row" style="display: none;">
                <div class="filter-area ">
                  <div>
                    <form method="POST" action="">
                      <label for="Entries" class="top_label">Show</label>
                      <select name="entries" id="entries">
                        <option value="show-10">10</option>
                        <option value="show-10">20</option>
                        <option value="show-10">30</option>
                        <option value="show-10">50</option>
                      </select>
                      <label for="Entries">Entries</label>
                    </form>
                  </div>
                  <div class="search">
                    <form class="search" action="">
                      <input type="text" placeholder="SEARCH" name="search" />
                      <button type="submit">
                        <img
                          src="/assets/images/icon/search-yellow.webp"
                          alt=""
                        />
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <div class="row table-section">
                <div style="overflow-x:auto; width: 100%;">
                  <table class="transaction table">
                    <tr>
                      <th>NO</th>
                      <th>AMOUNT</th>
                      <th>SECTOR</th>
                      <th>PAYMENT</th>
                      <th>DONATION DATE</th>
                      <th>TYPE</th>
                      <th>STATUS</th>
                      <th>ACTIONS</th>
                    </tr>

                    <tr
                      v-for="(inbox_data, index) in loadedPaginatedData"
                      :key="inbox_data.id"
                    >
                      <td v-if="current_page == 1">
                        {{ parseInt(index) + 1 }}
                      </td>
                      <td v-else>
                        {{
                          parseInt(perPageData) * (parseInt(current_page) - 1) +
                            parseInt(index) +
                            1
                        }}
                      </td>
                      <td>
                        {{ inbox_data.amount }} {{ inbox_data.currency_name }}
                      </td>
                      <td>
                        <span v-if="inbox_data.is_special_meal == 1"
                          >Special Meal Event</span
                        >
                        <span v-else>{{ inbox_data.sector_name }}</span>
                      </td>
                      <td>{{ inbox_data.payment_name }}</td>
                      <td>{{ inbox_data.date }}</td>
                      <td
                        class="type"
                        v-if="inbox_data.type == 1"
                        style="color: #faa61b;"
                      >
                        <b>Offline</b>
                      </td>
                      <td class="type" v-else style="color:green">
                        <b>Online</b>
                      </td>
                      <td v-if="inbox_data.status == 1" style="color: #faa61b;">
                        Draft
                      </td>
                      <td v-if="inbox_data.status == 2" style="color: #5d2e90">
                        Clarify
                      </td>
                      <td v-if="inbox_data.status == 3" style="color:green;">
                        Approved
                      </td>
                      <td v-if="inbox_data.status == 4" style="color: red;">
                        Disapproved
                      </td>
                      <td v-if="inbox_data.type == 1">
                        <span v-if="inbox_data.status == 3">
                          <a
                            :href="
                              adminPanelUrl +
                                '/donation_scholarship_receipt/' +
                                inbox_data.id
                            "
                            v-if="inbox_data.scholarship_amount > 0"
                          >
                            <button class="download_btn">
                              <img
                                class="_yellow"
                                src="/assets/images/icon/download-yellow.webp"
                                alt=""
                              />
                            </button>
                          </a>
                          <a
                            :href="
                              adminPanelUrl +
                                '/donation_receipt/1/' +
                                inbox_data.id
                            "
                            v-if="inbox_data.scholarship_amount < 1"
                          >
                            <button class="download_btn">
                              <img
                                class="_yellow"
                                src="/assets/images/icon/download-yellow.webp"
                                alt=""
                              />
                            </button>
                          </a>
                        </span>
                        <span v-else>Unavailable</span>
                      </td>

                      <td v-else>
                        <a
                          :href="
                            adminPanelUrl +
                              '/donation_receipt/' +
                              inbox_data.type +
                              '/' +
                              inbox_data.id
                          "
                        >
                          <button class="download_btn">
                            <img
                              class="_yellow"
                              src="/assets/images/icon/download-yellow.webp"
                              alt=""
                            />
                          </button>
                        </a>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="row">
                <!-- Pagination Start  -->

                <div v-if="totalPage > 1">
                  <div class="page-list">
                    <ul>
                      <li class="page-no">
                        <a href="javascript:;" @click="getData(1)">
                          <font-awesome-icon icon="angle-double-left" />
                        </a>
                      </li>

                      <li class="page-no" v-if="prev_page_url">
                        <a href="javascript:;" @click="getData(prevPage)">
                          <font-awesome-icon icon="angle-left" />
                        </a>
                      </li>
                      <li class="page-no" v-else>
                        <a href="javascript:;" style="cursor: not-allowed;">
                          <font-awesome-icon icon="angle-left" />
                        </a>
                      </li>

                      <li
                        v-for="pageNum in totalPage"
                        :key="pageNum"
                        :class="
                          pageNum > displayStartPageNumber &&
                          pageNum < displayEndPageNumber
                            ? 'page-no'
                            : 'page-no hidePaginationNumber'
                        "
                      >
                        <a
                          href="javascript:;"
                          @click="getData(pageNum)"
                          :class="current_page == pageNum ? 'active-page' : ''"
                        >
                          {{ pageNum }}
                        </a>
                      </li>

                      <li class="page-no" v-if="next_page_url">
                        <a href="javascript:;" @click="getData(nextPage)">
                          <font-awesome-icon icon="angle-right" />
                        </a>
                      </li>
                      <li class="page-no" v-else>
                        <a href="javascript:;" style="cursor: not-allowed;">
                          <font-awesome-icon icon="angle-right" />
                        </a>
                      </li>

                      <li class="page-no">
                        <a href="javascript:;" @click="getData(totalPage)">
                          <font-awesome-icon icon="angle-double-right" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- Pagination End -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--body section start -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
const apiResourceUrl = process.env.VUE_APP_APIURL;
export default {
  name: "Donor",
  data() {
    return {
      inboxes: {},
      adminPanelUrl: process.env.VUE_APP_ADMINPANELURL,

      perPageData: 10,
      paginationPageBtnLength: 5,
      current_page: null,
      prev_page_url: false,
      next_page_url: false,
      totalPage: null,
      prevPage: null,
      nextPage: null,
      loadedPaginatedData: {},
      displayStartPageNumber: null,
      displayEndPageNumber: null,
    };
  },

  methods: {
    initPagination() {
      if (this.inboxes.length > this.perPageData) {
        //Count Total page Start
        let totPage =
          parseInt(this.inboxes.length) / parseInt(this.perPageData);
        if (parseInt(this.inboxes.length) % parseInt(this.perPageData) != 0) {
          totPage = parseInt(totPage) + 1;
        }
        this.totalPage = totPage;
        //Count Total page End

        this.current_page = 1;
        this.displayStartPageNumber = 0;
        this.displayEndPageNumber = this.paginationPageBtnLength - 1;
        this.getData(1);
      } else {
        this.loadedPaginatedData = this.inboxes;
      }
    },

    paginationClientMenuOrganise() {
      this.prevPage = null;
      this.nextPage = null;

      if (this.totalPage == 1) {
        this.next_page_url = false;
        this.prev_page_url = false;
      } else {
        this.nextPage = this.current_page + 1;
        this.prevPage = this.current_page - 1;
      }

      if (this.current_page == this.totalPage) {
        this.next_page_url = false;
      }

      if (this.current_page == 1) {
        this.prev_page_url = false;
      }

      let interval = parseInt(this.paginationPageBtnLength / 2);
      if (this.totalPage <= this.paginationPageBtnLength) {
        this.displayStartPageNumber = 0;
        this.displayEndPageNumber = this.paginationPageBtnLength + 1;
      } else {
        if (this.current_page - interval <= 1) {
          this.displayStartPageNumber = 0;
          this.displayEndPageNumber = this.paginationPageBtnLength + 1;
        } else if (this.current_page + interval >= this.totalPage) {
          this.displayStartPageNumber =
            this.totalPage - this.paginationPageBtnLength;
          this.displayEndPageNumber = this.totalPage + 1;
        } else {
          this.displayStartPageNumber = this.current_page - interval - 1;
          this.displayEndPageNumber = this.current_page + interval + 1;
        }
      }
    },

    getData(pageNum) {
      let toNumber = pageNum * this.perPageData;
      let fromNumber = toNumber - this.perPageData;
      //console.log(this.inboxes.slice(fromNumber, toNumber));
      this.loadedPaginatedData = this.inboxes.slice(fromNumber, toNumber);
      this.current_page = pageNum;

      this.next_page_url = false;
      this.prev_page_url = false;
      if (pageNum < this.totalPage) {
        this.next_page_url = true;
      }

      if (pageNum > 1) {
        this.prev_page_url = true;
      }
      this.paginationClientMenuOrganise();
    },
  },

  async created() {
    await axios.get(apiResourceUrl + "/donor").then((response) => {
      this.inboxes = response.data.inboxes;
      //console.log(response.data.inboxes);
      this.initPagination();
    });
  },

  computed: {
    ...mapGetters(["user"]),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
