<template>
  <div>

    <HeroAreaProject />
    <HeroAreaBottomMenu />     
    <HomeJoinUs />     
    <OurMission />     
    <CorporatePartners />     
    <SubscribeNow />     
    <HomeAbout />     

  </div>
</template>

<script>

  import HeroAreaProject from './HeroAreaProject.vue'
  import HeroAreaBottomMenu from './HeroAreaBottomMenu.vue'
  import HomeJoinUs from './HomeJoinUs.vue'
  import OurMission from './OurMission.vue'
  import CorporatePartners from './CorporatePartners.vue'
  import SubscribeNow from './SubscribeNow.vue'
  import HomeAbout from './HomeAbout.vue'

  export default {
    name: 'Home',
    components:{
      HeroAreaProject,
      HeroAreaBottomMenu,
      HomeJoinUs,
      OurMission,
      CorporatePartners,
      SubscribeNow,
      HomeAbout
    }
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
