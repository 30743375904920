<template>
  <div>
    <div class="page_title_bg">
        <div class="container">
            <div class="row">
                <div class="col-xl-12 col-xs-12 donation_clearification_header text-center">
                    <p class="donation_clearification_title">Sign In</p>
                    <p class="white-bar"></p>
                    <p class="donation_clearification_text">Home / Sign In</p>
                </div>
            </div>
        </div>
    </div>
    <div class="all_login_bg forget_password">
        <div class="container">
            <div class="row">
                <div class="col-xl-12 text-center d-flex mobile_p0 justify-content-center">

                    <form @submit="formSubmit" class="form_block request_block">
                        <input type="text" placeholder="Enter your Email" v-model="email" class="form-control sing_in_input" required>
                        <input type="password" placeholder="Enter Your Password" v-model="password" class="form-control sing_in_input" required>
                       <!-- <p><input type="checkbox"> <span>Remember Me</span>  </p> -->
                        <button class="btn signIn_btn">SIGN IN</button>

                        <a href="javascript:;" @click="googleLogin" class="btn google_btn"><img src="/assets/images/AllLogin/gooogle_icon.webp" alt="google-icon"> SIGN IN WITH GOOGLE</a>
                        <router-link to="/forgot-password" class="forget_password_link"><u>Forgot Password</u></router-link>

                        <div class="login_page_link mt-3">Don't have an account? 
                            <router-link :to="{name: 'JoinUs'}">Sign up</router-link>
                        </div>
                    </form>


                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  //import swal from 'sweetalert';
  const apiResourceUrl = process.env.VUE_APP_APIURL;
  export default {
    name: 'SignIn',
    data() {
        return {
          email: '',
          password: ''
        };
    },
    methods: {
        formSubmit(e) {
            var newObject = this;
            e.preventDefault();
            axios.post(apiResourceUrl+'/login', {
                email: this.email,
                password: this.password
            })
            .then(function (response) {
                //console.log(response.data);
                localStorage.setItem('token', response.data.access_token)
                localStorage.setItem('userid', response.data.user.id)
                localStorage.setItem('email', response.data.user.email)
                localStorage.setItem('name', response.data.user.name)

                newObject.$store.dispatch('user', response.data.user);
                newObject.$router.push('donor')
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access_token;
                console.log('Authorized & Logged In');
            })
            .catch(function (error) {
                //let alertmessage = "";
                for (var prop in error.response.data) {
                    //alertmessage += error.response.data[prop]
                    newObject.$toast.error(error.response.data[prop]);
                }
                //  console.log(alertmessage)
                //swal(alertmessage, '', "error");
            });
        },

        googleLogin() {
            let rootObject = this;
            let url = apiResourceUrl + '/oauth_login/google';
            let params = "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=600,left=100,top=100";

            let newWindow = open( url , 'GOOGLE LOGIN', params);
            let authenticatedToken = '';
            let checkURLInterval = setInterval(function(){
                //console.log(newWindow.location.search);
                const urlParams = new URLSearchParams(newWindow.location.search);
                const tokenParam = urlParams.get('logintoken');
                if(tokenParam){
                    //console.log(tokenParam);
                    authenticatedToken = tokenParam;   
                    rootObject.clearIntervalWindow(newWindow, authenticatedToken, checkURLInterval) 
                }
                
            }, 1000);            
        },

        clearIntervalWindow(newWindow, authenticatedToken, checkURLInterval){
            clearInterval(checkURLInterval);
            newWindow.close();
            //console.log(authenticatedToken);
            this.proceedLoginUser(authenticatedToken);
        },

        proceedLoginUser(authenticatedToken){
            var rootObject = this;
            localStorage.setItem('token', authenticatedToken)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + authenticatedToken;

            axios.get(apiResourceUrl + '/userinfo')
            .then(function (response) {
                console.log('Authorized & Logged In');
                rootObject.$store.dispatch('user', response.data);  
                localStorage.setItem('userid', response.data.id)
                localStorage.setItem('email', response.data.email)
                localStorage.setItem('name', response.data.name)
                rootObject.$router.push('donor')
            })
            .catch(function (error) {
                let alertmessage = "";
                for (var prop in error.response.data) {
                    alertmessage += ' '+error.response.data[prop]
                }
                console.log(alertmessage);
                localStorage.removeItem("token");
                rootObject.$store.dispatch('user', null);
            });
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
