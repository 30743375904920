<template>
  <div>
    <!--body section start -->
    <div id="body-section" class="doner-home">
      <div class="container">
        <div class="row doner-profile">
          <div class="col-md-3">
            <div class="profile-left">
              <router-link :to="{ name: 'Donor' }" class="yellow-btn">
                Home
              </router-link>
              <router-link :to="{ name: 'DonorProfile' }" class="yellow-btn">
                Doner profile
              </router-link>
              <router-link
                :to="{ name: 'DonorScholarship' }"
                class="yellow-btn"
              >
                Students
              </router-link>
              <router-link
                :to="{ name: 'SpecialMealReport' }"
                class="green-btn"
              >
                Special Meals
              </router-link>
            </div>
          </div>
          <div class="col-md-9">
            <div class="title-bar">
              <div class="row">
                <div class="title">
                  <router-link :to="{ name: 'DonationClarification' }">
                    <img src="/assets/images/icon/plus.webp" alt="" /> New
                    donation Clerification
                  </router-link>
                </div>
              </div>
            </div>
            <div class="profile-right">
              <div class="row" style="display: none;">
                <div class="filter-area ">
                  <div class="search">
                    <form class="search" action="">
                      <input type="text" placeholder="SEARCH" name="search" />
                      <button type="submit">
                        <img
                          src="/assets/images/icon/search-yellow.webp"
                          alt=""
                        />
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <div class="row table-section">
                <div style="overflow-x:auto; width: 100%;">
                  <div class="row">
                    <div
                      class="col-md-4"
                      v-for="(image_data, index) in imageArr"
                      :key="index"
                    >
                      <img :src="image_data" alt="" class="images" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--body section start -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
const apiResourceUrl = process.env.VUE_APP_APIURL;
export default {
  name: "DonorScholarshipFeedBack",
  data() {
    return {
      imageArr: {},
      adminPanelUrl: process.env.VUE_APP_ADMINPANELURL,
    };
  },

  methods: {},

  async created() {
    var EventID = this.$route.params.id;
    console.log(EventID);
    await axios
      .get(apiResourceUrl + "/special-meal-report-feedback/" + EventID)
      .then((response) => {
        this.imageArr = response.data.imageArr;
        //console.log(response.data.imageArr);
      });
  },

  computed: {
    ...mapGetters(["user"]),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.images {
  border: 1px solid #ddd;
  margin-bottom: 15px;
}
</style>
