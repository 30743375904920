<template>
  <div class="container">
     <div class="row news-section">
        <h1 class="col-md-2 main-logo">
          <router-link to="/">
            <img src="/assets/images/main-logo.webp" style="width: 12rem;" height="100%" width="100%" alt="main logo">
          </router-link>
        </h1>
        <!-- <div class="col-md-1"> </div> -->
        <div class="col-md-9">
           <p class="news-content text-justify">A mad passionate group of people working to inspire one nation at a time Bidyanondo (Learn for Fun) is an educational
              voluntary organization originating from Bangladesh. The official registration No. is S-12258/2015...
           </p>
           <router-link to="/about" class="news-btn">READ MORE</router-link>
        </div>
     </div>
  </div>
</template>

<script>
  export default {
    name: 'HomeAbout'
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
