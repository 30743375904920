<template>
  <div>
    <!--body section start -->
    <div class="sponsor_hero_area custom_page_title_bg">
        <div class="container">
            <div class="row">
                <h1 class="text-center">Sponsor Us</h1><br>
                <hr class="line">
                <p class="text-center">Home / Sponsor Us</p>
            </div>
        </div>
    </div>

    <!-- Sponsor body start  -->
    <div id="body-section">
        <div class="container sponsor-list">
            <div class="row _blocks" v-if="sponsorList.length > 0">

                <div class="col-md-3 single_block" v-for="studentData in sponsorList" :key="studentData.id">
                    <img :src="studentData.student_image" alt="Sponsor" class="img-responsive">
                    <div class="salary">
                        <ul>
                            <li><p>Yearly</p></li>
                            <li>
                                <p>
                                    ৳ {{studentData.scholarship_amount}} 
                                </p>
                            </li>
                        </ul>
                        
                        <h3>{{studentData.name}}</h3>
                        <router-link :to="{name: 'SponsorDetails', params:{id:studentData.id}}">
                            Read More . . .
                        </router-link>
                        <div class="mt-3"></div>
                        <router-link :to="{name: 'SponsorNow', params:{id:studentData.id}}" class="_button">Sponsor</router-link>
                    </div>
                </div>
          
            </div>
            <div class="row _blocks emptymessages" v-else>
                <h3>Currently There Are No Students Available To Be Sponsored.</h3>
            </div>

        </div>
    </div>
    <!--body section start -->
  </div>
</template>

<script>
  import axios from 'axios'
  const apiResourceUrl = process.env.VUE_APP_APIURL;
  export default {
    name: 'Sponsor',
    data(){
      return{
        sponsorList : {}
      }
    },
    async created(){
        await axios.get(apiResourceUrl+'/sponsor').then((response) => {
            this.sponsorList = (response.data);
            //console.log(response.data);
        })
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
