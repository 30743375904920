<template>
  <div>
    <!-- hero area start -->
    <div class="page_title_bg">
        <div class="container">
            <div class="row">
                <div class="col-xl-12 col-xs-12 donation_clearification_header text-center">
                    <p class="donation_clearification_title">explore projects</p>
                    <p class="white-bar"></p>
                    <p class="donation_clearification_text">Home / Explore Projects</p>
                </div>
            </div>
        </div>
        </div>
    <!-- hero area end -->

    <!-- body area start -->
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-sm-12 col-12 text-center all_project_body_title ">All Projects</div>
            <p class="grey-bar-center"></p>

            <div class="row individual_project" v-for="item in projectList" :key="item.id" >
                <div class="col-xl-4 col-sm-4 col-12 p-0">
                    <div class="project_image">
                        <router-link :to="{name: 'Donate', params:{id:item.id}}">
                            <img :src="item.project_image" alt="project1">
                        </router-link>
                    </div>
                </div>

                <div class="col-xl-8 col-sm-8 col-12">
                    <div class="project_title_and_description ">
                        <div class="project_title">
                            <router-link :to="{name: 'Donate', params:{id:item.id}}" style="color: #000;">
                                {{ item.name }} 
                            </router-link>
                        </div>

                        <div class="project_des" v-if="item.description.length<165">
                            <pre v-html="item.description"></pre>
                        </div>
                        <div class="project_des" v-else>

                            <span :id="'projectshortdesc_' + item.id" style="display: inline;">
                                {{ item.description.substring(0,165)+" " }}
                            </span>

                            <a href="javascript:;" @click="readMore(item.id)" :id="'projectreadmore_' + item.id" class="explore_projects_read_more_btn"> Read more</a>

                            <span :id="'projectfulldesc_' + item.id">
                                <pre v-html="item.description"></pre>
                            </span>
                        </div>

                        <button class="explore_projects_donate_btn">
                            <router-link :to="{name: 'Donate', params:{id:item.id}}">
                                Donate Now
                            </router-link>
                        </button>
                    </div>
                </div>
            </div>
        
        </div>

    </div>

    <!-- body area end -->
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  //import axios from 'axios'
  //const apiResourceUrl = process.env.VUE_APP_APIURL;
  export default {
    name: 'ExploreProject',
    data(){
      return{
        //projectList : {}
      }
    },
    methods: {
        readMore(id){
            document.getElementById("projectshortdesc_"+id).style.display = "none";
            document.getElementById("projectreadmore_"+id).style.display = "none";
            document.getElementById("projectfulldesc_"+id).style.display = "inline";
        }
    },
    computed: {
      ...mapGetters({
        projectList : 'getProjectData'
      })
    },
    async created(){
        // await axios.get(apiResourceUrl+'/projects').then((response) => {
        //     this.projectList = (response.data.projects);
        // })
    },
    mounted(){

    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    pre {
        white-space: pre-wrap;       /* Since CSS 2.1 */
        white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
        white-space: -pre-wrap;      /* Opera 4-6 */
        white-space: -o-pre-wrap;    /* Opera 7 */
        word-wrap: break-word;       /* Internet Explorer 5.5+ */
        font-family: "Baloo Da 2";
        font-size: 1.125rem;
    }
</style>
