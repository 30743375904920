<template>
  <div>
    <div class="page_title_bg">
      <div class="container">
        <div class="row">
          <div
            class="col-xl-12 col-xs-12 donation_clearification_header text-center"
          >
            <p class="donation_clearification_title">AUDIT REPORT</p>
            <p class="white-bar"></p>
            <p class="donation_clearification_text">Home / Audit Report</p>
          </div>
        </div>
      </div>
    </div>

    <!--Audit report section start-->
    <div class="container">
      <div class="row">
        <div class="offset-xl-3 col-xl-6 col-12">
          <div class="audit-report-table">
            <ul class="audit_table_title">
              <li>SL</li>
              <li>Audit Year</li>
              <li>Audit Report</li>
            </ul>

            <!-- <ul>
              <li>1</li>
              <li>2013-2016</li>
              <li>
                <a
                  href="https://drive.google.com/file/d/1ur_SLDrIPjJjFRIPbeQhCj5ZbcugzfO0/view"
                  target="_blank"
                  ><img src="/assets/images/icon/pdf.webp" alt="audit-report" />
                  View PDF
                </a>
              </li>
            </ul> -->

            <ul>
              <li>2</li>
              <li>2016-2017</li>
              <li>
                <a
                  href="https://drive.google.com/file/d/1tMfVkbu3wOKofrQrlCmJ_xBrR2tlKU9W/view?usp=share_link"
                  target="_blank"
                  ><img src="/assets/images/icon/pdf.webp" alt="audit-report" />
                  View PDF
                </a>
              </li>
            </ul>

            <ul>
              <li>3</li>
              <li>2017-2018</li>
              <li>
                <a
                  href="https://drive.google.com/file/d/1oEBfKg6sRgPm0C6kEq7P5eab3qNMrJpo/view?usp=share_link"
                  target="_blank"
                  ><img src="/assets/images/icon/pdf.webp" alt="audit-report" />
                  View PDF
                </a>
              </li>
            </ul>

            <ul>
              <li>4</li>
              <li>2019</li>
              <li>
                <a
                  href="https://drive.google.com/file/d/1ZfST3K9xS6oIOEWmyBZ6njuBk9-XfLS-/view?usp=share_link"
                  target="_blank"
                  ><img src="/assets/images/icon/pdf.webp" alt="audit-report" />
                  View PDF
                </a>
              </li>
            </ul>

            <ul>
              <li>5</li>
              <li>Jan 2020 - Jun 2020</li>
              <li>
                <a
                  href="https://drive.google.com/file/d/1FO0qsu_SxB8-OeP4QiI9ONUKhL3tTkN-/view?usp=share_link"
                  target="_blank"
                  ><img src="/assets/images/icon/pdf.webp" alt="audit-report" />
                  View PDF
                </a>
              </li>
            </ul>

            <ul>
              <li>6</li>
              <li>2020-2021</li>
              <li>
                <a
                  href="https://drive.google.com/file/d/1WRBfGyVNtnbNypBE6ic32GZfeh5ctsmy/view?usp=share_link"
                  target="_blank"
                  ><img src="/assets/images/icon/pdf.webp" alt="audit-report" />
                  View PDF
                </a>
              </li>
            </ul>

            <ul>
              <li>7</li>
              <li>July 2021- June 2022</li>
              <li>
                <a
                  href="https://drive.google.com/file/d/1fbraBkibEmHR7VseQLhmrT0EJRViQNBG/view?usp=share_link"
                  target="_blank"
                  ><img src="/assets/images/icon/pdf.webp" alt="audit-report" />
                  View PDF
                </a>
              </li>
            </ul>

            <!-- <ul>
              <li>1</li>
              <li>2016-2017</li>
              <li>
                <a
                  href="https://drive.google.com/open?id=1P_6jnDSXJau3h9ccVohqPooFLqlWjvIp"
                  target="_blank"
                  ><img src="/assets/images/icon/pdf.webp" alt="audit-report" />
                  View PDF
                </a>
              </li>
            </ul>

            <ul>
              <li>2</li>
              <li>2017-2018</li>
              <li>
                <a
                  href="https://drive.google.com/open?id=1Vo9E-HGFvTiEH8lHWaaptaaliE1o1wrn"
                  title=""
                  target="_blank"
                >
                  <img src="/assets/images/icon/pdf.webp" alt="audit-report" />
                  View PDF
                </a>
              </li>
            </ul>

            <ul>
              <li>3</li>
              <li>2018-2019</li>
              <li>
                <a
                  href="https://drive.google.com/file/d/1x9FBwzr9QfROqlhxajeIDRNzl5_JQKuq/view"
                  title=""
                  target="_blank"
                >
                  <img src="/assets/images/icon/pdf.webp" alt="audit-report" />
                  View PDF
                </a>
              </li>
            </ul>

            <ul>
              <li>4</li>
              <li>Jan 2020-June 2020</li>
              <li>
                <a
                  href="https://drive.google.com/file/d/1mn9etI15M8m-qtvpvWImnmWuPIBdQ0fa/view"
                  title=""
                  target="_blank"
                >
                  <img src="/assets/images/icon/pdf.webp" alt="audit-report" />
                  View PDF
                </a>
              </li>
            </ul> -->
          </div>
        </div>
      </div>
    </div>

    <!--Audit Report section end-->
  </div>
</template>

<script>
export default {
  name: "AuditReport",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
