<template>
  <div>
    <div class="page_title_bg">
      <div class="container">
        <div class="row">
          <div
            class="col-xl-12 col-xs-12 donation_clearification_header text-center"
          >
            <p class="donation_clearification_title">Genaral Donation</p>
            <p class="white-bar"></p>
            <p class="donation_clearification_text">Home / Genaral Donation</p>
          </div>
        </div>
      </div>
    </div>

    <br /><br />
    <!--donation page start -->
    <div class="container">
      <div class="row" style="display: none;">
        <div class="col-md-11 col-xs-12 offset-md-1">
          <div class="select_area">
            <router-link class="bangladesh bangladesh_shadow" to="/donate"
              >Genaral Donation</router-link
            >
            <router-link class="international" to="/donation-clarification"
              >Donation Clarification</router-link
            >
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 col-xs-12">
          <p class="donate-title">DONATE NOW</p>
          <div class="row">
            <div
              class="col-md-2 col-4 p-1"
              v-for="item in donationAmounts"
              :key="item.id"
            >
              <p
                :class="{ active: selectedamount == item.amount }"
                class="price-value tool"
                :data-tip="item.description"
                @click="loadAmount(item.amount)"
              >
                ৳ {{ item.amount }}
              </p>
            </div>

            <div class="col-md-2 col-4 p-1">
              <input
                type="text"
                placeholder="OTHER AMOUNT"
                name=""
                class="customer-donation-input customer-donation"
                v-model="other_amount"
                @keyup="customAmount"
              />
            </div>

            <div class="col-md-12 col-12 p-1">
              <textarea
                class="customer-donation-input customer-donation"
                placeholder="TYPE YOUR MESSAGE"
                v-model="comments"
                style="margin-top: 15px; height: 100px; padding-top: 10px;"
              ></textarea>
              <!-- <input
                class="customer-donation-input customer-donation"
                placeholder="ENTER YOUR MESSAGE"
                v-model="comments"
                style="margin-top: 15px;"
              /> -->
            </div>
          </div>
          <br />
          <label class="checkbox-inline" @click="donorInfo">
            <input type="checkbox" name="" /> &nbsp;
            <span @click="donorInfo">I WANT TO PROVIDE MY INFORMATION</span>
          </label>
          <br />
          <div class="row" v-if="show">
            <div class="col-md-6 col-6 p-1">
              <input
                type="text"
                placeholder="Your Name"
                class="customer-donation-input customer-donation"
                v-model="cus_name"
              />
            </div>
            <div class="col-md-6 col-6 p-1">
              <input
                type="text"
                placeholder="Contact Number"
                class="customer-donation-input customer-donation"
                v-model="cus_phone"
              />
            </div>
            <div class="col-md-12 col-12 p-1">
              <input
                type="text"
                placeholder="Email Address"
                class="customer-donation-input customer-donation"
                v-model="cus_email"
              />
            </div>
          </div>

          <div class="chose-payment">
            <p>
              <span
                style="font-size: 22px; font-weight: 600; margin-right:2rem; "
                >Choose Payment
              </span>
              <span>
                <img src="/assets/images/icon/lock.webp" alt="secure" />
                SECURE</span
              >
            </p>
          </div>

          <div class="row">
            <div class="col-md-6 col-6 p-1" style="display: none;">
              <button class="btn pay-with-btn" @click="formSubmit('ssl')">
                <font-awesome-icon icon="credit-card" />
                Card / Mobile / Bank
              </button>
            </div>

            <div class="col-md-6 col-6 p-1" style="display: none;">
              <button class="btn pay-with-btn" @click="formSubmit('paypal')">
                <font-awesome-icon :icon="['fab', 'paypal']" />
                Paypal
              </button>
            </div>

            <div class="col-md-6 col-12 offset-md-3 p-1">
              <button class="btn pay-with-btn" @click="openModal()">
                Donate Now
              </button>
            </div>
          </div>

          <div class="row" style="display: none;">
            <div class="col-md-12 col-12 p-1">
              <router-link
                :to="{ name: 'BankInfo' }"
                class="btn pay-with-btn additional_bank mt-2 "
                >Additional Bank Information</router-link
              >
            </div>
          </div>
        </div>
        <!--col-md-7 end-->
      </div>
    </div>
    <!--container section-->

    <div class="donation-payment-option" style="display:none;">
      <picture>
        <source
          media="(min-width:650px)"
          srcset="/assets/images/donation/donation-payment-option.webp"
        />
        <source
          media="(min-width:465px)"
          srcset="/assets/images/donation/donate_payment_mobile.webp"
        />
        <img
          src="/assets/images/donation/donate_payment_mobile.webp"
          alt="Flowers"
          style="width:100%;"
        />
      </picture>
    </div>

    <br />
    <br />
    <br />
    <!--donation page end -->

    <div id="myModal" class="modal _gallery_">
      <div id="_close_div" class="close_div" @click="closeModal()"></div>
      <div class="modal-content" style="width: 400px; margin-top: 10rem;">
        <span class="close cursor" @click="closeModal()">&times;</span>
        <div
          class="col-md-12"
          style="padding-left: 30px; padding-right: 30px; padding-top: 50px; padding-bottom: 50px;"
        >
          <div class="row">
            <div class="col-md-12 col-12 p-1">
              <button class="btn pay-with-btn" @click="formSubmit('ssl')">
                <font-awesome-icon icon="credit-card" />
                Card / Mobile / Bank
              </button>
            </div>

            <div class="col-md-12 col-12 p-1">
              <button class="btn pay-with-btn" @click="formSubmit('paypal')">
                <font-awesome-icon :icon="['fab', 'paypal']" />
                Paypal
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
import axios from "axios";
const apiResourceUrl = process.env.VUE_APP_APIURL;
const adminPanelUrl = process.env.VUE_APP_ADMINPANELURL;
const appRootUrl = process.env.VUE_APP_ROOTURL;

export default {
  name: "GeneralDonate",
  data() {
    return {
      donationAmounts: {},
      selectedamount: "",
      other_amount: "",
      comments: "",
      show: false,
      cus_name: "",
      cus_email: "",
      cus_phone: "",
      currency_bdt: {},
      currency_usd: {},
    };
  },
  methods: {
    loadAmount(amount) {
      this.selectedamount = amount;
      //console.log(amount);
    },

    customAmount() {
      this.selectedamount = "";
    },

    donorInfo() {
      if (this.show) {
        this.show = false;
      } else {
        this.show = true;
      }
    },

    openModal() {
      document.getElementById("myModal").style.display = "block";
      document.getElementById("_close_div").style.display = "block";
    },

    closeModal() {
      document.getElementById("myModal").style.display = "none";
    },

    formSubmit(payment_type) {
      if (
        (!this.selectedamount && !this.other_amount) ||
        (!this.selectedamount && this.other_amount < 1)
      ) {
        swal("Amount is required !", "", "error");
        return;
      }

      const form = new FormData();
      form.append("user_id", localStorage.getItem("userid"));

      var takenAmount = 0;
      if (this.other_amount) {
        takenAmount = this.other_amount;
      } else {
        takenAmount = this.selectedamount;
      }

      form.append("comments", this.comments);
      form.append("donate_way", 1);
      form.append("cus_name", this.cus_name);
      form.append("cus_email", this.cus_email);
      form.append("cus_phone", this.cus_phone);

      this.closeModal();

      if (payment_type == "ssl") {
        form.append("amount", takenAmount);
        form.append("custom_amount", takenAmount);
        form.append("taken_amount", takenAmount);

        if (takenAmount < this.currency_bdt.min_donate_amount) {
          swal(
            "Minimum Amount is " +
              this.currency_bdt.min_donate_amount +
              " Taka",
            "",
            "error"
          );
          return;
        }

        if (takenAmount > this.currency_bdt.max_donate_amount) {
          swal(
            "Maximum Amount is " +
              this.currency_bdt.max_donate_amount +
              " Taka",
            "",
            "error"
          );
          return;
        }

        this.sslPayment(form);
      }

      if (payment_type == "paypal") {
        var minimumConvertedDonateAmount =
          this.currency_usd.min_donate_amount *
          this.currency_usd.tk_convert_amount;

        var maxmumConvertedDonateAmount =
          this.currency_usd.max_donate_amount *
          this.currency_usd.tk_convert_amount;

        //console.log(maxmumConvertedDonateAmount)

        if (takenAmount < minimumConvertedDonateAmount) {
          swal(
            "Minimum Amount is " + minimumConvertedDonateAmount + " Taka",
            "",
            "error"
          );
          return;
        }

        if (takenAmount > maxmumConvertedDonateAmount) {
          swal(
            "Maximum Amount is " + maxmumConvertedDonateAmount + " Taka",
            "",
            "error"
          );
          return;
        }

        var convertedAmount = takenAmount / this.currency_usd.tk_convert_amount;

        form.append("amount", convertedAmount);
        form.append("custom_amount", convertedAmount);
        form.append("taken_amount", convertedAmount);

        this.paypalPayment(form);
      }
    },

    sslPayment(form) {
      delete axios.defaults.headers.common["Authorization"];
      var config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      var childWindow = window.open(appRootUrl + "/loading", "_blank");

      axios
        .post(adminPanelUrl + "/vue_client_pay_with_sslcommerz", form, config)
        .then(function(response) {
          //console.log(response.data);
          // window.open(
          //   response.data,
          //   '_blank'
          // );
          childWindow.location.href = response.data;
        })
        .catch(function(error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          //console.log(alertmessage)
          swal(alertmessage, "", "error");
        });
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("token");
    },

    paypalPayment(form) {
      delete axios.defaults.headers.common["Authorization"];
      var config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      var childWindow = window.open(appRootUrl + "/loading", "_blank");

      axios
        .post(adminPanelUrl + "/vue_client_pay_with_paypal", form, config)
        .then(function(response) {
          //console.log(response.data);
          // window.open(
          //   response.data,
          //   '_blank'
          // );
          childWindow.location.href = response.data;
        })
        .catch(function(error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          //console.log(alertmessage)
          swal(alertmessage, "", "error");
        });
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("token");
    },

    defaultLoadAmount(donationAmounts) {
      if (donationAmounts.length > 0) {
        this.loadAmount(donationAmounts[0].amount);
      }
    },
  },
  computed: {},
  created() {
    axios.get(apiResourceUrl + "/donation").then((response) => {
      this.donationAmounts = response.data.generalDonate;
      this.currency_bdt = response.data.currency_bdt;
      this.currency_usd = response.data.currency_usd;
      this.defaultLoadAmount(this.donationAmounts);
    });
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.donate-left-section {
  text-align: justify;
}
.select_area a {
  border-radius: 7px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
</style>
