<template>
  <div>
    <!-- contact header area start -->
    <div class="page_title_bg branch-title_section">
        <div class="container">
        <div class="row">
            <div class="col-xl-12 col-xs-12 donation_clearification_header text-center">
                <p class="donation_clearification_title">BRANCHES INFORMATION</p>
                <p class="white-bar"></p>
                <p class="donation_clearification_text">Home / Branch Information</p>
            </div>
        </div>
        </div>
    </div>
    <!-- contact header area end -->
    <div class="container mb-5">
        <div class="row">
           
            <div class="col-xl-4 col-sm-6 col-12 branch-area" v-for="branchData in branchList" :key="branchData.id">
                <div class="single-branch">
                    <div class="branch-title">{{branchData.name}}</div>
                    <div class="branch-contact">
                        <ul>
                            <li> <img src="/assets/images/icon/branch_number.webp" alt="branch-info"><b>Call Us:</b> </li>
                            <li class="branch-number"> {{branchData.contact_no}} </li>
                        </ul>
                        <ul>
                            <li> <img src="/assets/images/icon/branch_location.webp" alt="branch-info"> <b>Location:</b></li>
                            <li> {{branchData.address}} </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    </div>

  </div>
</template>

<script>
  import axios from 'axios'
  const apiResourceUrl = process.env.VUE_APP_APIURL;
  export default {
    name: 'Branch',
    data(){
      return{
        branchList : {}
      }
    },
    async created(){
      await axios.get(apiResourceUrl+'/branchs').then((response) => {
        this.branchList = response.data;
        console.log(response.data);
      })
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
