<template>
  <div>
    <!-- about header area start -->
    <div class="page_title_bg_s">
        <div class="container">
            <div class="row">
                <div class="col-xl-12 col-xs-12 donation_clearification_header text-center">
                    
                </div>
            </div>
        </div>
    </div>
    <!-- about header area end -->


    <!-- about body section start -->

    <div class="container success_">
        <div class="row">
            <div class="success_img">
                <img src="/assets/images/icon/success.gif" alt="">
                <h1>Success</h1>
                <p>Your transaction is successfully completed. <br> Please Check email for receipt.</p>
            </div>

            <ul style="display:none;">
                <li>
                    <label style="color: black;font-weight: bold;font-size:26px;">Donation Information</label>
                </li>
                <li>
                    <b style="color:#1f8000">Donation Amount :</b> 
                    <b>23 Taka (BDT)</b>
                </li>
                <li>
                    <b style="color:#1f8000">Donor Name :</b> 
                    Mirza Tahmida
                </li>
                <li>
                    <b style="color:#1f8000">Donor Email :</b> tahmidtajik99@gmail.com
                </li>
                <li>
                    <b style="color:#1f8000">Donation Description :</b> 
                    Qurbani for underprivileged people
                </li>
                <li>
                    <b style="color:#1f8000">Comments :</b>
                    <pre>adasdsd</pre>
                </li>
                <li>
                    <a class="btn btn-danger btn-sm" href="http://localhost:8888/Bidyanondo-V3/public/donation_receipt/3/23897" title="Download Receipt" download style="color: #fff; background-color: green; border-color: green;"> 
                        Download Receipt
                    </a>
                </li>
            </ul>
        </div>
    </div>


    <!-- about body section start -->
  </div>
</template>

<script>
  export default {
    name: 'Failed'
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
