<template>
  <div>
    <!-- about header area start -->
    <div class="page_title_bg_s">
        <div class="container">
            <div class="row">
                <div class="col-xl-12 col-xs-12 donation_clearification_header text-center">
                    
                </div>
            </div>
        </div>
    </div>
    <!-- about header area end -->


    <!-- about body section start -->

    <div class="container success_ failed_img">
        <div class="row">
            <div class="success_img ">
                <img src="/assets/images/icon/failed.gif" alt="">
                <h1>Failed</h1>
                <p>Your transaction is failed. Please try again.</p>
            </div>
            <div class="success_msg">

            </div>
        </div>
    </div>


    <!-- about body section start -->
  </div>
</template>

<script>
  export default {
    name: 'Failed'
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
