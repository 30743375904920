<template>
  <div>
    <!-- gallery header area start -->
    <div class="page_title_bg">
      <div class="container">
        <div class="row">
          <div
            class="col-xl-12 col-xs-12 donation_clearification_header text-center"
          >
            <p class="donation_clearification_title">
              <span class="gallery-link"> GALLERY </span>
            </p>
            <p class="donation_clearification_text">Home / Media / Gallery</p>
          </div>
        </div>
      </div>
    </div>
    <!-- gallery header area end -->

    <!-- gallery submenu bar start -->
    <div class="container">
      <div class="row press_submenu button-group filter-button-group">
        <div class="col-xl-12 col-12 ">
          <ul class=" text-center myBtnContainer">
            <li class="press_submenu_items">
              <button
                @click="filterData('all')"
                :class="activeCategory == 'all' ? 'activecategory' : ''"
              >
                all
              </button>
            </li>

            <li
              class="press_submenu_items"
              v-for="item in gallery_categories"
              :key="item.id"
            >
              <button
                @click="filterData(item.id)"
                :class="activeCategory == item.id ? 'activecategory' : ''"
              >
                <!-- <span class="press_submenu_bullet">&#8226;</span>  -->
                {{ item.name }}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- gallery submenu bar end -->

    <!-- gallery area start -->
    <div class="container _gallery_">
      <div class="row protfolio-items gallery_section grid">
        <div
          class="col-xl-4 col-xs-12 col-sm-6 covid"
          v-for="(list, index) in loadedPaginatedData"
          :key="list.id"
        >
          <div class="card text-center press_card_items">
            <img
              :src="list.gallery_image"
              style="height: 100%"
              :alt="list.name"
              @click="
                openModal();
                currentSlide(index + 1);
              "
              class="hover-shadow cursor"
            />
            <div class="press_card_item_info_area">
              <div class="card-body press_card_item_info">
                <div class="press_card_item_description">
                  {{ list.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Pagination Start  -->
      <div v-if="totalPage > 1">
        <div class="page-list">
          <ul>
            <li class="page-no">
              <a href="javascript:;" @click="getData(1)">
                <font-awesome-icon icon="angle-double-left" />
              </a>
            </li>

            <li class="page-no" v-if="prev_page_url">
              <a href="javascript:;" @click="getData(prevPage)">
                <font-awesome-icon icon="angle-left" />
              </a>
            </li>
            <li class="page-no" v-else>
              <a href="javascript:;" style="cursor: not-allowed;">
                <font-awesome-icon icon="angle-left" />
              </a>
            </li>

            <li
              v-for="pageNum in totalPage"
              :key="pageNum"
              :class="
                pageNum > displayStartPageNumber &&
                pageNum < displayEndPageNumber
                  ? 'page-no'
                  : 'page-no hidePaginationNumber'
              "
            >
              <a
                href="javascript:;"
                @click="getData(pageNum)"
                :class="current_page == pageNum ? 'active-page' : ''"
              >
                {{ pageNum }}
              </a>
            </li>

            <li class="page-no" v-if="next_page_url">
              <a href="javascript:;" @click="getData(nextPage)">
                <font-awesome-icon icon="angle-right" />
              </a>
            </li>
            <li class="page-no" v-else>
              <a href="javascript:;" style="cursor: not-allowed;">
                <font-awesome-icon icon="angle-right" />
              </a>
            </li>

            <li class="page-no">
              <a href="javascript:;" @click="getData(totalPage)">
                <font-awesome-icon icon="angle-double-right" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <!-- Pagination End -->

      <br /><br />
    </div>

    <div id="myModal" class="modal _gallery_">
      <div id="_close_div" class="close_div" @click="closeModal()"></div>
      <div class="modal-content">
        <span class="close cursor" @click="closeModal()">&times;</span>
        <div
          class="mySlides"
          v-for="listData in loadedPaginatedData"
          :key="listData.id"
        >
          <img :src="listData.gallery_big_image" />
          <div class="pop_description">{{ listData.name }}</div>
        </div>

        <a class="prev" @click="plusSlides(-1)">&#10094;</a>
        <a class="next" @click="plusSlides(1)">&#10095;</a>

        <div class="caption-container">
          <p id="caption"></p>
        </div>
      </div>
    </div>

    <!-- gallery area end -->
  </div>
</template>

<script>
var slideIndex = 1;
import axios from "axios";
const apiResourceUrl = process.env.VUE_APP_APIURL;
export default {
  name: "Gallery",
  data() {
    return {
      gallery_categories: {},
      gallery_images: {},
      dynamic_gallery_images: {},
      activeCategory: "all",

      perPageData: 9,
      paginationPageBtnLength: 5,
      current_page: null,
      prev_page_url: false,
      next_page_url: false,
      totalPage: null,
      prevPage: null,
      nextPage: null,
      loadedPaginatedData: {},
      displayStartPageNumber: null,
      displayEndPageNumber: null,
    };
  },
  methods: {
    openModal() {
      document.getElementById("myModal").style.display = "block";
      document.getElementById("_close_div").style.display = "block";
    },

    closeModal() {
      document.getElementById("myModal").style.display = "none";
    },

    plusSlides(n) {
      this.showSlides((slideIndex += n));
    },

    currentSlide(n) {
      this.showSlides((slideIndex = n));
    },

    showSlides(n) {
      var i;
      var slides = document.getElementsByClassName("mySlides");
      if (n > slides.length) {
        slideIndex = 1;
      }
      if (n < 1) {
        slideIndex = slides.length;
      }
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }
      slides[slideIndex - 1].style.display = "block";
    },

    filterData(id) {
      //console.log(id)
      this.activeCategory = id;
      this.dynamic_gallery_images = [];
      this.loadedPaginatedData = [];

      if (id == "all") {
        this.dynamic_gallery_images = this.gallery_images;
      } else {
        for (var prop in this.gallery_images) {
          //console.log(this.gallery_images[prop]['gallery_category_id'])
          if (this.gallery_images[prop]["gallery_category_id"] == id) {
            this.dynamic_gallery_images.push(this.gallery_images[prop]);
          }
        }
      }

      //console.log(this.dynamic_gallery_images);

      this.initPagination();
    },

    initPagination() {
      if (this.dynamic_gallery_images.length > 0) {
        //Count Total page Start
        let totPage =
          parseInt(this.dynamic_gallery_images.length) /
          parseInt(this.perPageData);
        if (
          parseInt(this.dynamic_gallery_images.length) %
            parseInt(this.perPageData) !=
          0
        ) {
          totPage = parseInt(totPage) + 1;
          this.totalPage = totPage;
        }
        //Count Total page End

        this.current_page = 1;
        this.displayStartPageNumber = 0;
        this.displayEndPageNumber = 1 + this.paginationPageBtnLength;

        this.getData(1);
      }
    },

    paginationClientMenuOrganise() {
      this.prevPage = null;
      this.nextPage = null;

      if (this.totalPage == 1) {
        this.next_page_url = false;
        this.prev_page_url = false;
      } else {
        this.nextPage = this.current_page + 1;
        this.prevPage = this.current_page - 1;
      }

      if (this.current_page == this.totalPage) {
        this.next_page_url = false;
      }

      if (this.current_page == 1) {
        this.prev_page_url = false;
      }

      let interval = parseInt(this.paginationPageBtnLength / 2);
      if (this.totalPage <= this.paginationPageBtnLength) {
        this.displayStartPageNumber = 0;
        this.displayEndPageNumber = this.paginationPageBtnLength + 1;
      } else {
        if (this.current_page - interval <= 1) {
          this.displayStartPageNumber = 0;
          this.displayEndPageNumber = this.paginationPageBtnLength + 1;
        } else if (this.current_page + interval >= this.totalPage) {
          this.displayStartPageNumber =
            this.totalPage - this.paginationPageBtnLength;
          this.displayEndPageNumber = this.totalPage + 1;
        } else {
          this.displayStartPageNumber = this.current_page - interval - 1;
          this.displayEndPageNumber = this.current_page + interval + 1;
        }
      }
    },

    getData(pageNum) {
      let toNumber = pageNum * this.perPageData;
      let fromNumber = toNumber - this.perPageData;
      //console.log(this.dynamic_gallery_images.slice(fromNumber, toNumber));
      this.loadedPaginatedData = this.dynamic_gallery_images.slice(
        fromNumber,
        toNumber
      );
      this.current_page = pageNum;

      this.next_page_url = false;
      this.prev_page_url = false;
      if (pageNum < this.totalPage) {
        this.next_page_url = true;
      }

      if (pageNum > 1) {
        this.prev_page_url = true;
      }
      this.paginationClientMenuOrganise();
    },
  },

  async created() {
    await axios.get(apiResourceUrl + "/gallery").then((response) => {
      this.gallery_categories = response.data.gallery_categories;
      this.gallery_images = response.data.galleries;
      this.dynamic_gallery_images = response.data.galleries;
      //console.log(response.data);
      //console.log(response.data.gallery_categories);

      this.initPagination();
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
