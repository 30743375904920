<template>
  <div>
    <!--body section start -->
    <div class="sponsor_hero_area custom_page_title_bg">
        <div class="container">
            <div class="row">
                <h1 class="text-center">Volunteer Registration</h1><br>
                <hr class="line">
                <p class="text-center">Home / Volunteer Registration</p>
            </div>
        </div>
    </div>

    <!-- Registration Form start -->
    <div id="body-section">
        <div class="container">
            <div class="row">
                <div class="select_area">
                    <router-link class="bangladesh bangladesh_shadow" to="/bangladeshi-volunteer">For Bangladeshi Volunteers</router-link>
                    <router-link class="international" to="/international-volunteer">For International Volunteers</router-link>
                </div>
            </div>
            <div class="registration-form">                
                <form @submit="formSubmit" enctype="multipart/form-data">
                    <div class="row">
                        <div class="col-md-6">
                            <label for="first_name">First Name*</label>
                            <input type="text" id="first_name" name="first_name" v-model="first_name" placeholder="Enter Your First Name" required>
                        </div>

                        <div class="col-md-6">
                            <label for="first_name">Last Name*</label>
                            <input type="text" id="last_name" name="last_name" v-model="last_name" placeholder="Enter Your Last Name" required>
                        </div>

                        <div class="col-md-6">
                            <label for="email">Email *</label>
                            <input type="text" id="email" name="email" v-model="email" placeholder="Enter Your Email" required>
                        </div>

                        <div class="col-md-6">  
                            <label for="contact_no">Contact no *</label>
                            <input type="text" id="contact_no" name="contact_no" v-model="contact_no" placeholder="Enter Contact Number" required>
                        </div>

                        <div class="col-md-6">
                            <label for="gender_id">Gender *</label>
                            <select name="gender_id" id="gender_id" v-model="gender_id" required>
                                <option v-for="gender_data in genders" :key="gender_data.id" :value="gender_data.id">
                                    {{gender_data.gender_name}}
                                </option>
                            </select>
                        </div>
                                            
                        <div class="col-md-6">
                            <label for="contact_id">Branch *</label>
                            <select name="contact_id" id="contact_id" v-model="contact_id" required>
                                <option v-for="contact_data in contacts" :key="contact_data.id" :value="contact_data.id">
                                    {{contact_data.name}}
                                </option>
                            </select>
                        </div>

                        <div class="col-md-12 image">
                            <label for="picture">Your Picture</label>
                            <input type="file" id="picture" name="picture" ref="file" @change="onFileSelected">
                            <p class="image-size">(recommended image size 160x160)</p>
                        </div>                
                    </div>

                    <div class="row">
                        <div class="submit-area">
                            <input type="submit" value="SEND" class="btn signIn_btn contactbtn">
                        </div>
                    </div>
                </form>
            </div>            
        </div>
    </div>

    <!--Registration Form end -->
  </div>
</template>

<script>
  import axios from 'axios'
  import swal from 'sweetalert';
  const apiResourceUrl = process.env.VUE_APP_APIURL;
  export default {
    name: 'BangladeshiVolunteer',
    data(){
      return{
        genders : {},
        contacts: {},
        gender_id:'',
        contact_id:'',
        first_name:'',
        last_name:'',
        email:'',
        contact_no:'',
        profile_image:'',
      }
    },
    methods: {

        formSubmit(e) {

            e.preventDefault();
            var formObject = this;

            const form = new FormData()
            form.append('gender_id', this.gender_id)
            form.append( 'contact_id', this.contact_id)
            form.append('first_name', this.first_name)
            form.append('last_name', this.last_name)
            form.append('email', this.email)
            form.append('contact_no', this.contact_no) 
            form.append('profile_image', this.profile_image) 

            var config = {
              headers: { 'Content-Type': 'multipart/form-data' }
            }

            axios.post(apiResourceUrl+'/save-volunteer-registration', form, config)
            .then(function (response) {
                //console.log(response.data.message);
                swal(response.data.message, '', "success");
                formObject.resetForm();
            })
            .catch(function (error) {
                let alertmessage = "";
                for (var prop in error.response.data) {
                    alertmessage += ' '+error.response.data[prop]
                }
                //console.log(alertmessage)
                //alert(alertmessage)
                swal(alertmessage, '', "error");

            });
        },

        resetForm(){

            this.first_name = '';
            this.last_name = '';
            this.email = '';
            this.contact_no = '';
            this.gender_id = this.genders[0]['id']
            this.contact_id = this.contacts[0]['id']

            const input = this.$refs.file;
            input.type = 'text';
            input.type = 'file';
        },

        onFileSelected(event){
            //console.log(event.target.files[0]);
            this.profile_image = event.target.files[0]
        },
    },
    async created(){
      await axios.get(apiResourceUrl+'/get-volunteer-form-data').then((response) => {
        this.genders = response.data.result.genders;
        this.contacts = response.data.result.contacts;

        this.gender_id = response.data.result.genders[0]['id']
        this.contact_id = response.data.result.contacts[0]['id']

        //console.log(response.data.result.contacts);
      })
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
