<template>
  <div>
    <div class="container">
      <div class="row header_social">
        <div class="header-social-icon  d-flex justify-content-end">
          <ul class="d-flex flex-row justify-content-between">
            <li>
              <a
                :href="old_site_url"
                target="_blank"
                style="color: #e41f28; font-weight: bold; text-decoration: underline;"
              >
                Previous Site
              </a>
            </li>
            <li>
              <a :href="settingsInfo.facebook_social_link"
                ><i class="fab fa-facebook-f"></i
              ></a>
            </li>
            <li>
              <a :href="settingsInfo.linkdin_social_link"
                ><i class="fab fa-linkedin-in"></i
              ></a>
            </li>
            <li class="lastsocial">
              <a :href="settingsInfo.goole_plus_social_link"
                ><i class="fab fa-youtube"></i
              ></a>
            </li>
          </ul>
        </div>
      </div>
      <div class="row .header d-flex justify-content-between">
        <div class="left_menu">
          <h1 class="main-logo d-flex">
            <router-link to="/">
              <img
                src="/assets/images/main-logo.webp"
                height="100%"
                width="100%"
                :alt="myAppName"
              />
            </router-link>
            <div class="hidden-social">
              <ul class="d-flex flex-row justify-content-between">
                <li style="margin-top:3px">
                  <a
                    :href="old_site_url"
                    target="_blank"
                    style="color: #e41f28; font-weight: bold; text-decoration: underline;"
                  >
                    Previous Site
                  </a>
                </li>
                <li>
                  <a :href="settingsInfo.facebook_social_link"
                    ><i class="fab fa-facebook-f"></i
                  ></a>
                </li>
                <li>
                  <a :href="settingsInfo.linkdin_social_link"
                    ><i class="fab fa-linkedin-in"></i
                  ></a>
                </li>
                <li>
                  <a :href="settingsInfo.goole_plus_social_link"
                    ><i class="fab fa-youtube"></i
                  ></a>
                </li>
              </ul>
            </div>
          </h1>
        </div>
        <div class="right-menu">
          <nav id="main-menu" class="main-menu float-right">
            <ul>
              <li><router-link to="/">Home</router-link></li>
              <li><router-link to="/about">About Us</router-link></li>
              <li><router-link to="/donate">Donation</router-link></li>
              <li>
                <router-link :to="{ name: 'SpecialMeal' }"
                  >Special Meal</router-link
                >
              </li>
              <li v-if="!user" style="display:none">
                <router-link :to="{ name: 'JoinUs' }">Join Us</router-link>
              </li>
              <li v-if="!user" class="most-right-main-menu-list">
                <router-link to="/signin">Sign In </router-link>
              </li>
              <li v-if="user">
                <router-link to="/donor">Profile </router-link>
              </li>
              <li v-if="user" class="most-right-main-menu-list">
                <a href="#" @click="logOutNow">Log Out</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
const apiResourceUrl = process.env.VUE_APP_APIURL;

export default {
  name: "AppHeader",
  data() {
    return {
      old_site_url: process.env.VUE_APP_OLDSITEURL,
    };
  },

  methods: {
    async logOutNow(e) {
      e.preventDefault();
      await axios.get(apiResourceUrl + "/logout");
      localStorage.removeItem("token");
      this.$store.dispatch("user", null);
      this.$router.push("/");
      console.log("Successfully Logged Out");
    },
  },

  computed: {
    ...mapGetters({
      user: "user",
      myAppName: "getAppName",
      settingsInfo: "settings",
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
