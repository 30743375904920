<template>
  <div>
    <div class="page_title_bg">
      <div class="container">
        <div class="row">
          <div
            class="col-xl-12 col-xs-12 donation_clearification_header text-center"
          >
            <p class="donation_clearification_title">Sign Up</p>
            <p class="white-bar"></p>
            <p class="donation_clearification_text">Home / Sign Up</p>
          </div>
        </div>
      </div>
    </div>

    <div class="all_login_bg forget_password">
      <div class="container">
        <div class="row">
          <div
            class="col-xl-12 mobile_p0 text-center d-flex justify-content-center"
          >
            <form @submit="formSubmit" class="form_block request_block">
              <input
                type="text"
                placeholder="Your Name"
                v-model="name"
                class="form-control sing_up_input"
                required
              />
              <input
                type="email"
                placeholder="Your Email"
                v-model="email"
                class="form-control sing_up_input"
                required
              />
              <input
                type="password"
                placeholder="Password"
                v-model="password"
                class="form-control sing_up_input"
                required
              />
              <input
                type="password"
                placeholder="Repeat Your Password"
                v-model="password_confirmation"
                class="form-control sing_up_input"
                required
              />
              <!-- <p><input type="checkbox"> <span>Remember Me</span>  </p> -->
              <button class="btn signIn_btn">REGISTER</button>
              <a href="javascript:;" @click="googleLogin" class="btn google_btn"
                ><img
                  src="/assets/images/AllLogin/gooogle_icon.webp"
                  alt="google-icon"
                />
                SIGN UP WITH GOOGLE</a
              >
              <div class="login_page_link mt-3">
                Already have an account?
                <router-link to="/signin">Sign in</router-link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";
const apiResourceUrl = process.env.VUE_APP_APIURL;
export default {
  name: "JoinUs",
  data() {
    return {
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
    };
  },
  methods: {
    formSubmit(e) {
      e.preventDefault();
      var formObject = this;
      axios
        .post(apiResourceUrl + "/register", {
          name: this.name,
          email: this.email,
          password: this.password,
          password_confirmation: this.password_confirmation,
        })
        .then(function(response) {
          //console.log(response.data.message);
          //alert(response.data.message)
          swal(response.data.message, "", "success");
          formObject.name = "";
          formObject.email = "";
          formObject.password = "";
          formObject.password_confirmation = "";
        })
        .catch(function(error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += error.response.data[prop];
          }
          //console.log(alertmessage)
          swal(alertmessage, "", "error");
          //alert(alertmessage)
        });
    },

    googleLogin() {
      let rootObject = this;
      let url = apiResourceUrl + "/oauth_login/google";
      let params =
        "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=600,left=100,top=100";

      let newWindow = open(url, "GOOGLE LOGIN", params);
      let authenticatedToken = "";
      let checkURLInterval = setInterval(function() {
        //console.log(newWindow.location.search);
        const urlParams = new URLSearchParams(newWindow.location.search);
        const tokenParam = urlParams.get("logintoken");
        if (tokenParam) {
          //console.log(tokenParam);
          authenticatedToken = tokenParam;
          rootObject.clearIntervalWindow(
            newWindow,
            authenticatedToken,
            checkURLInterval
          );
        }
      }, 1000);
    },

    clearIntervalWindow(newWindow, authenticatedToken, checkURLInterval) {
      clearInterval(checkURLInterval);
      newWindow.close();
      //console.log(authenticatedToken);
      this.proceedLoginUser(authenticatedToken);
    },

    proceedLoginUser(authenticatedToken) {
      var rootObject = this;
      localStorage.setItem("token", authenticatedToken);
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + authenticatedToken;

      axios
        .get(apiResourceUrl + "/userinfo")
        .then(function(response) {
          console.log("Authorized & Logged In");
          rootObject.$store.dispatch("user", response.data);
          localStorage.setItem("userid", response.data.id);
          localStorage.setItem("email", response.data.email);
          localStorage.setItem("name", response.data.name);
          rootObject.$router.push("donor");
        })
        .catch(function(error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          localStorage.removeItem("token");
          rootObject.$store.dispatch("user", null);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
