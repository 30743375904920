<template>
  <div>
   <div class="page_title_bg">
      <div class="container">
          <div class="row">
              <div class="col-xl-12 col-xs-12 donation_clearification_header text-center">
                  <p class="donation_clearification_title">about us</p>
                  <p class="white-bar"></p>
                  <p class="donation_clearification_text">Home / About Us</p>
              </div>
          </div>
      </div>
    </div>

    <div class="container">
        <div class="row">
            <div class="col-xl-12 about">
                <div class="about_team_title">
                    {{aboutInfo.about_title}}
                </div>
                <div class="about_team_subtitle">
                    {{aboutInfo.about_sub_title}}
                </div>
                <div class="about_team_description">
                    {{aboutInfo.about_short_brief}}
                </div>
            </div>

            <div class="col-xl-12 about">
                <div class="about_team_title">
                    Vision & Mission
                    <p class="grey-bar-align-left"></p>
                </div>
                
                <div class="about_vision_mission">
                    <div class="about_vision ">
                        <span>Vision:</span> {{aboutInfo.vission}} <br> <br>
                    </div>
                    <div class="about_mission">
                        <span>Mission:</span> {{aboutInfo.mission}}
                    </div>
                </div>
            </div>

            <div class="col-xl-12 about">
                <div class="about_team_title">
                    Where we work
                    <p class="grey-bar-align-left"></p>
                </div>
                <div class="about_team_description">
                    {{aboutInfo.where_we_work}}
                </div>
            </div>

            <div class="col-xl-12 about">
                <div class="about_team_title">
                    Our story
                    <p class="grey-bar-align-left"></p>
                </div>
                <div class="about_team_description">
                    {{aboutInfo.our_story}}
                </div>
            </div>
        </div>
    </div>

    <div class="about_join_us_section">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 col-xs-12 flex-center-position">
                    <div class="about_join_us_video_section text-center">
                        <div class="about_join_us_video">
                            <iframe style="max-width: 100%;" :src="aboutInfo.about_video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>
                        </div>
                    </div>
                </div>

                <div class="col-xl-6 col-xs-12">
                    <div class="about_join_us_section text-left">
                        <div class="about_join_us_title">
                            Join us
                        </div>
                        <div class="about_join_us_subtitle">
                            {{aboutInfo.sign_up_donor_message}}
                        </div>
                        <div class="about_join_us_btn_area">
                            <router-link :to="{name: 'JoinUs'}" class="btn about_join_us_btn">
                                Join us
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container mt-5 mb-5 about-join-section">
      <div class="row ">
        <div class="col-md-4 join-single-block text-center">
          <div class="join-single-image">
            <img src="/assets/images/icon/hand.webp" alt="DONATIONS1">
          </div>
          <p class="join-single-heading"> DONATIONS</p>
          <p class="join-single-text"> Bidyanondo is always with you at any crisis in
            Bangladesh to help and support you.</p>
          <div class="about_donet_btn">
            
            <router-link :to="{name: 'DonationClarification'}" class="btn about_now_btn">
                Donate Now
            </router-link>
          </div>
        </div>

        <div class="col-md-4 join-single-block text-center">
          <div class="join-single-image">
            <img src="/assets/images/icon/volunteer.webp" alt="DONATIONS2">
          </div>
          <p class="join-single-heading"> VOLUNTEER</p>
          <p class="join-single-text"> Bidyanondo will be proud to have you as a volunteer.</p>
          <div class="about_donet_btn">
            <router-link :to="{name: 'BangladeshiVolunteer'}" class="btn about_now_btn">
              Registration
            </router-link>
          </div>
        </div>

        <div class="col-md-4 join-single-block text-center">
            <div class="join-single-image">
              <img src="/assets/images/icon/sopnsor.webp" alt="DONATIONS3">
            </div>
            <p class="join-single-heading"> SPONSORSHIP</p>
            <p class="join-single-text"> Support us by your donations to create a better future.</p>
            <div class="about_donet_btn">
                <router-link to="/sponsor" class="btn about_now_btn">Sponsor</router-link>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  //import axios from 'axios'
  //const apiResourceUrl = process.env.VUE_APP_APIURL;
  export default {
    name: 'About',
    data(){
      return{
        //aboutInfo : {}
      }
    },
    computed: {
      ...mapGetters({
        aboutInfo : 'settings'
      })
    },
    created(){
      // axios.get(apiResourceUrl+'/websitesettings').then((response) => {
      //     this.aboutInfo = (response.data.settings);
      // })
    },
    mounted(){
        
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
