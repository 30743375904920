<template>
  <div>
    <!--body section start -->
    <div class="sponsor_hero_area custom_page_title_bg">
        <div class="container">
            <div class="row">
                <h1 class="text-center">Sponsor Us</h1><br>
                <hr class="line">
                <p class="text-center">Home / Sponsor Us</p>
            </div>
        </div>
    </div>

    <!-- Sponsor list body start -->
    <div id="body-section">
        <div class="container sponsor-list sponsor-details">
            <div class="row">
                <div class="col-md-4">
                    <img :src="studentData.student_image" alt="Sponsor">
                    <div class="salary">
                        <ul>
                            <li><p>Yearly </p></li>
                            <li>
                                <p> 
                                   ৳ {{studentData.scholarship_amount}}
                                </p>
                            </li>
                        </ul>
                        
                        <h3>{{studentData.name}}</h3>
                        <!-- <a href="#">Read More....</a> -->
                        <div class="mt-3"></div>
                            <router-link :to="{name: 'SponsorNow', params:{id:studentData.id}}" class="_button">Sponsor</router-link>
                    </div>
                </div>
                <div class="col-md-8 biography">
                    <div class="row">
                        <h2><img src="/assets/images/icon/pen.webp" alt=""> Biography</h2>
                        <div class="underline-1"></div>
                        <p>
                            {{studentData.biography}}
                        </p>
                    </div>

                    <div class="row">
                        <h2><img src="/assets/images/icon/pen.webp" alt=""> Similar Profiles</h2>
                        <div class="underline-2"></div>
                        <div class="similar-profile">

                            <router-link v-for="studentInfo in students" :key="studentInfo.id" :to="{name: 'SponsorDetails', params:{id:studentInfo.id}}">
                                <img :src="studentInfo.student_image" :alt="studentInfo.name">
                            </router-link>

                        </div>
                    </div>
                </div>
            </div>            
        </div>
    </div>

    <!-- Sponsor list body end -->
  </div>
</template>

<script>
  import axios from 'axios'
  const apiResourceUrl = process.env.VUE_APP_APIURL;
  export default {
    name: 'SponsorDetails',
    data(){
      return{
        studentData : '',
        students : {}
      }
    },
    computed: {
      
    },
    
    methods:{
        
    },

    created(){
        var SponsorDetailsID = this.$route.params.id;
        console.log(SponsorDetailsID)
        axios.get(apiResourceUrl+'/sponsor-details/'+SponsorDetailsID).then((response) => {
            this.studentData = (response.data.student);
            this.students = (response.data.students);

            //console.log(response.data)
        })
    },
    mounted(){
        
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
