<template>
  <div>
    

    <!-- video album header area start -->
    <div class="page_title_bg">
      <div class="container">
        <div class="row">
          <div
            class="col-xl-12 col-xs-12 donation_clearification_header text-center"
          >
            <p class="donation_clearification_title">
              <span><a href="galarry.html">GALLERY.</a></span>
              <span class="gallery-link">VIDEO ALBUM </span
              ><span><a href="press.html">.PRESS</a></span>
            </p>
            <p class="donation_clearification_text">
              Home / Media / Video Album
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- video album header area end -->

    <!-- video album area start -->
    <div class="container">
      <div class="row video-section">


        <div class="col-xl-4 col-6 col-sm-6 video_category" v-for="list in loadedPaginatedData" :key="list.id">
          <router-link :to="{name: 'videoDetails', params:{id:list.id}}">              
            <div class="video_album_items">
              <img class="--bg-white" :src="list.image" alt=""/>
              <div></div>
              <img class="play-btn" src="/assets/images/icon/play-solid.svg" alt=""/>
            </div>
            <div class="video_description_area">
              <img src="/assets/images/icon/video.png" alt="video-album" />
              <div class="video_description">
                <span class="video_album_video_description_count">{{list.counts}}</span>
                <br />
                <span class="video_album_video_description_title">
                    {{list.name}}
                </span>
              </div>
            </div>
          </router-link>
        </div>

        <!-- Pagination Start  -->
        <div class="col-12" v-if="totalPage > 1">
          <div class="page-list">
            <ul>

              <li class="page-no"><a href="javascript:;" @click="getData(1)">
                  <font-awesome-icon icon="angle-double-left" />
              </a></li>

              <li class="page-no" v-if="prev_page_url"><a href="javascript:;" @click="getData(prevPage)">
                <font-awesome-icon icon="angle-left" />
              </a></li>
              <li class="page-no" v-else><a href="javascript:;" style="cursor: not-allowed;">
                <font-awesome-icon icon="angle-left" />
              </a></li>
              
              <li v-for="(pageNum) in totalPage" :key="pageNum" :class="(pageNum > displayStartPageNumber && pageNum < displayEndPageNumber)?'page-no':'page-no hidePaginationNumber'">
                <a href="javascript:;" @click="getData(pageNum)"  :class="current_page==pageNum?'active-page':''">
                  {{ pageNum }}
                </a>
              </li>

              <li class="page-no" v-if="next_page_url"><a href="javascript:;" @click="getData(nextPage)">
                <font-awesome-icon icon="angle-right" />
              </a></li>
              <li class="page-no" v-else><a href="javascript:;" style="cursor: not-allowed;">
                <font-awesome-icon icon="angle-right" />
              </a></li>

              <li class="page-no"><a href="javascript:;" @click="getData(totalPage)">
                  <font-awesome-icon icon="angle-double-right" />
              </a></li>

            </ul>
          </div>
        </div>
        <!-- Pagination End -->
        
            
      </div>
    </div>
    <!-- video album area end -->


  </div>
</template>

<script>
  import axios from 'axios'
  const apiResourceUrl = process.env.VUE_APP_APIURL;
  export default {
    name: 'Video',
    data(){
      return{
        vedioList : {}, 
        
        perPageData : 9,
        paginationPageBtnLength : 5,  
        current_page : null,
        prev_page_url : false,
        next_page_url : false,
        totalPage : null,
        prevPage : null,
        nextPage : null,
        loadedPaginatedData : {},
        displayStartPageNumber : null,
        displayEndPageNumber : null,
      }
    },
    
    methods: {
      
      initPagination(){

          if(this.vedioList.length > 0){

              //Count Total page Start
              let totPage = parseInt(this.vedioList.length) / parseInt(this.perPageData);
              if((parseInt(this.vedioList.length) % parseInt(this.perPageData)) != 0){
                  totPage = parseInt(totPage) +1;
                  this.totalPage = totPage
              }
              //Count Total page End  

              this.current_page = 1;
              this.displayStartPageNumber = 0;
              this.displayEndPageNumber = 1 + this.paginationPageBtnLength;

              this.getData(1);
          }
      },

      paginationClientMenuOrganise(){
          this.prevPage = null
          this.nextPage = null

          if(this.totalPage==1){
            this.next_page_url = false;
            this.prev_page_url = false;
          }else{
            this.nextPage = this.current_page + 1;
            this.prevPage = this.current_page - 1;
          }

          if(this.current_page == this.totalPage){
            this.next_page_url = false;
          }

          if(this.current_page == 1){
            this.prev_page_url = false;
          }

          let interval = parseInt(this.paginationPageBtnLength / 2);
          if(this.totalPage <= this.paginationPageBtnLength){
              this.displayStartPageNumber = 0;
              this.displayEndPageNumber = this.paginationPageBtnLength +1;
          }else{
              if((this.current_page - interval) <= 1){
                  this.displayStartPageNumber = 0;
                  this.displayEndPageNumber = this.paginationPageBtnLength +1;
              }
              else if((this.current_page + interval) >= this.totalPage){
                  this.displayStartPageNumber = this.totalPage - this.paginationPageBtnLength;
                  this.displayEndPageNumber = this.totalPage +1;
              }else{
                  this.displayStartPageNumber = this.current_page - interval - 1;
                  this.displayEndPageNumber = this.current_page + interval +1;
              }
          }
      },

      getData(pageNum){
          let toNumber = pageNum*this.perPageData;
          let fromNumber = toNumber - this.perPageData;
          //console.log(this.vedioList.slice(fromNumber, toNumber));
          this.loadedPaginatedData = this.vedioList.slice(fromNumber, toNumber);
          this.current_page = pageNum;

          this.next_page_url = false; 
          this.prev_page_url = false;
          if(pageNum < this.totalPage){
              this.next_page_url = true; 
          }

          if(pageNum > 1){
              this.prev_page_url = true; 
          }            
          this.paginationClientMenuOrganise();
      }

    },

    computed: {
      
    },
    async created(){
        await axios.get(apiResourceUrl+'/videos').then((response) => {
            this.vedioList = response.data;
            //console.log(response.data);

            this.initPagination();
        })
    },
    mounted(){

    },
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
