import { createStore } from "vuex";
//import axios from 'axios'

//const apiResourceUrl = process.env.VUE_APP_APIURL;

const store = createStore({
   	state: {
    	appName: "Bidyanondo Logo",
    	user : null,
    	projectData: {},
    	homeHighlightedProjectData: {},
    	homeAreaBottomProjectData: {},
    	settings : {},
    	isloaded : false
	},
	getters: {
	    getAppName: state => {
	      return state.appName
	    },

	    user: state => {
	      return state.user
	    },

	    settings: state => {
	      return state.settings
	    },

	    getProjectData: state => {
	    	return state.projectData
	    },

	    homeHighlightedProjectData: state => {
	    	return state.homeHighlightedProjectData
	    },

	    homeAreaBottomProjectData: state => {
	    	return state.homeAreaBottomProjectData
	    },

	    isloaded: state => {
	    	return state.isloaded
	    },
  	},
	mutations: {
		user(state, user){
			state.user = user;
		},

		settings(state, settings){
			state.settings = settings;
		},

		projectData(state, projectData){
			state.projectData = projectData;
		},

		homeHighlightedProjectData(state, projectData){
			state.homeHighlightedProjectData = projectData;
		},

		homeAreaBottomProjectData(state, projectData){
			state.homeAreaBottomProjectData = projectData;
		},

		isloaded(state, payload){
			state.isloaded = payload;
		}

	},
	actions: {
		user(context, user){
			context.commit('user', user);
		},

		settings(context, settings){
			context.commit('settings', settings);
		},

		projectData(context, projectData){
			context.commit('projectData', projectData);
		},

		homeHighlightedProjectData(context, projectData){
			context.commit('homeHighlightedProjectData', projectData);
		},

		homeAreaBottomProjectData(context, projectData){
			context.commit('homeAreaBottomProjectData', projectData);
		},

		isloaded(context, payload){
			context.commit('isloaded', payload);
		}
	}
})

export default store;