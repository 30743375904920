<template>
  <div>
    <div class="page_title_bg">
        <div class="container">
        <div class="row">
            <div class="col-xl-12 col-xs-12 donation_clearification_header text-center">
                <p class="donation_clearification_title">Our Campaigns</p>
                <p class="white-bar"></p>
                <p class="donation_clearification_text">Home / Our Campaigns</p>
            </div>
        </div>
        </div>
    </div>

    <div class="campaigns_section">
        <div class="container">
            <div class="row" v-if="campaignList.length > 0">
                <div class="col-12 top_text"></div>
                <div class="col-12" v-for="list in campaignList" :key="list.id">
                    <div class="single_block">
                        <div class="_image">
                            <img :src="list.cover_image" :alt="list.title">
                        </div>

                        <div class="_description">
                            <h1>{{list.title}}</h1>
                            <p>
                              {{ list.description.substring(0,300)+".." }}
                            </p>
                            <p style="color: red">Date : {{list.date}}</p>
                            <router-link :to="{name: 'CampaignDetails', params:{id:list.id}}" class="_button">  
                              Read more
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>  
            <div class="row emptymessages" v-else>
                <div class="col-12 emptymessages" style="padding-top: 25px;">
                    <h3>Currently There Are No Campaign Available.</h3>
                </div>
            </div>
        </div>
    </div>

  </div>
</template>

<script>
  import axios from 'axios'
  const apiResourceUrl = process.env.VUE_APP_APIURL;
  export default {
    name: 'Campaign',
    data(){
      return{
        campaignList : {}
      }
    },
    async created(){
        await axios.get(apiResourceUrl+'/campaign').then((response) => {
            this.campaignList = response.data;
            //console.log(response.data);
        })
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
