<template>
  <div>
    <br />
    <!--Special Meal Page start -->
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-xs-12">
          <p class="donate-title">SPECIAL MEAL</p>
          <div class="row">
            <div class="table-responsive" style="border: 1px solid #ddd;">
              <table class="table table-stripe">
                <thead>
                  <th>Date</th>
                  <th>Branch</th>
                  <th>Meal Item</th>
                  <th class="displaynone">Cost Per Unit</th>
                  <th>Quantity</th>
                  <th colspan="2">Sub Total</th>
                </thead>
                <tbody id="dynamic_row">
                  <tr
                    class="tr"
                    v-for="(input, index) in inputs"
                    :key="index"
                    :id="'tr_' + index"
                  >
                    <td>
                      <input
                        type="text"
                        class="form-control valid"
                        name="eventArr[1][event_date]"
                        placeholder="Any Date"
                        v-model="input.event_date"
                        onfocus="(this.type='date'); this.showPicker();"
                        onblur="(this.type='text');"
                        onchange="(this.type='text');"
                      />
                    </td>
                    <td>
                      <div class="form-group" style="width: 150px">
                        <select
                          :class="'form-control branch_' + index"
                          :name="'eventArr[' + index + '][branch_id]'"
                          @change="loadSingleQuantity(index)"
                          v-model="input.branch_id"
                          required
                        >
                          <option value="">Any Branch</option>
                          <option
                            v-for="branchData in branch_arr"
                            :key="branchData.id"
                            :value="branchData.id"
                          >
                            {{ branchData.name }}
                          </option>
                        </select>
                      </div>
                    </td>
                    <td>
                      <div class="form-group" style="width: 150px">
                        <select
                          :class="'form-control specilalfund  spmeal_' + index"
                          :id="index"
                          :name="'eventArr[' + index + '][food_item_id]'"
                          @change="loadSingleQuantity(index)"
                          v-model="input.food_item_id"
                          required
                        >
                          <option
                            v-for="itemData in food_items"
                            :key="itemData.id"
                            :value="itemData.id"
                          >
                            {{ itemData.name }}
                          </option>
                        </select>
                      </div>
                    </td>
                    <td class="displaynone">
                      <div class="form-group" style="width: 150px">
                        <input
                          type="text"
                          :class="'form-control bdtcostperplate_' + index"
                          :name="'eventArr[' + index + '][cost_per_plate]'"
                          v-model="input.cost_per_plate"
                          required
                          readonly
                        />
                      </div>
                    </td>
                    <td>
                      <div class="form-group" style="width: 150px">
                        <input
                          type="number"
                          :class="'form-control bdtnoplate_' + index"
                          min=""
                          :name="'eventArr[' + index + '][no_unit]'"
                          placeholder="No of Unit"
                          @change="specialMealAmountCalculate"
                          v-model="input.no_unit"
                          @blur="specialMealAmountCalculate"
                          required
                        />
                      </div>
                    </td>
                    <td>
                      <div class="form-group" style="width: 150px">
                        <input
                          type="text"
                          :class="'form-control bdtcostsubtotlal_' + index"
                          :name="'eventArr[' + index + '][subtotal]'"
                          v-model="input.subtotal"
                          readonly
                          required
                        />
                      </div>
                    </td>
                    <td>
                      <a
                        href="javascript:;"
                        @click="removeRow(index)"
                        class="btn btn-danger btn-sm"
                        ><i class="fa fa-times-circle"></i
                      ></a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 col-12 p-1">
              <textarea
                class="customer-donation-input customer-donation"
                placeholder="ENTER YOUR MESSAGE"
                v-model="comments"
                style="margin-top: 15px; height: 100px; padding-top: 10px;"
              ></textarea>
              <!-- <input
                class="customer-donation-input customer-donation"
                placeholder="ENTER YOUR MESSAGE"
                v-model="comments"
                style="margin-top: 15px; border-color: #ddd;"
              /> -->
            </div>
          </div>

          <div class="amount-wrapper">
            <div class="row">
              <a @click="addNewRow" class="btn btn-success btn-sm addmore_btn">
                <i class="fa fa-plus-circle"></i> Add More
              </a>
            </div>
            <div class="col-md-6 offset-md-3">
              <div class="row" style="margin-top: 25px;">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="unit" id="bdt_unit_label">Total Quantity</label>
                    <input
                      name="no_unit"
                      v-model="total_no_unit"
                      id="bdt_no_unit"
                      placeholder="No"
                      class="price-per-plate form-control"
                      type="number"
                      readonly
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="total_amount">Total Amount</label>
                    <input
                      placeholder="Total"
                      v-model="total_amount"
                      name="amount"
                      id="bdt_amount"
                      class="total_amount form-control"
                      type="text"
                      readonly
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <br />

          <label class="checkbox-inline" @click="donorInfo">
            <input type="checkbox" name="" /> &nbsp;
            <span @click="donorInfo">I WANT TO PROVIDE MY INFORMATION</span>
          </label>
          <br />
          <div class="row" v-if="show">
            <div class="col-md-6 col-6 p-1">
              <input
                type="text"
                placeholder="Your Name"
                class="customer-donation-input customer-donation"
                v-model="cus_name"
              />
            </div>
            <div class="col-md-6 col-6 p-1">
              <input
                type="text"
                placeholder="Contact Number"
                class="customer-donation-input customer-donation"
                v-model="cus_phone"
              />
            </div>
            <div class="col-md-12 col-12 p-1">
              <input
                type="text"
                placeholder="Email Address"
                class="customer-donation-input customer-donation"
                v-model="cus_email"
              />
            </div>
          </div>
          <div class="chose-payment">
            <p>
              <span
                style="font-size: 22px; font-weight: 600; margin-right:2rem; "
                >Choose Payment
              </span>
              <span>
                <img src="/assets/images/icon/lock.webp" alt="secure" />
                SECURE</span
              >
            </p>
          </div>
          <div class="row">
            <div class="col-md-6 col-6 p-1" style="display: none;">
              <button class="btn pay-with-btn" @click="formSubmit('ssl')">
                <font-awesome-icon icon="credit-card" />
                Card / Mobile / Bank
              </button>
            </div>
            <div class="col-md-6 col-6 p-1" style="display: none;">
              <button class="btn pay-with-btn" @click="formSubmit('paypal')">
                <font-awesome-icon :icon="['fab', 'paypal']" />
                Paypal
              </button>
            </div>
            <div class="col-md-6 col-12 offset-md-3 p-1">
              <button class="btn pay-with-btn" @click="openModal()">
                Donate Now
              </button>
            </div>
          </div>
          <div class="row" style="display: none;">
            <div class="col-md-12 col-12 p-1">
              <router-link
                :to="{ name: 'BankInfo' }"
                class="btn pay-with-btn additional_bank mt-2 "
                >Additional Bank Information</router-link
              >
            </div>
          </div>

          <div class="disclaimer">
            Disclaimer: To get execution photos and event updates, please
            complete the registration process with your valid email address. For
            any inquiries, please send us a mail on
            <a href="mailto:support@bidyanondo.org" class="mailto"
              >support@bidyanondo.org</a
            >
          </div>
        </div>
      </div>
    </div>
    <!--container section-->

    <div id="myModal" class="modal _gallery_">
      <div id="_close_div" class="close_div" @click="closeModal()"></div>
      <div class="modal-content" style="width: 400px; margin-top: 10rem;">
        <span class="close cursor" @click="closeModal()">&times;</span>
        <div
          class="col-md-12"
          style="padding-left: 30px; padding-right: 30px; padding-top: 50px; padding-bottom: 50px;"
        >
          <div class="row">
            <div class="col-md-12 col-12 p-1">
              <button class="btn pay-with-btn" @click="formSubmit('ssl')">
                <font-awesome-icon icon="credit-card" />
                Card / Mobile / Bank
              </button>
            </div>

            <div class="col-md-12 col-12 p-1">
              <button class="btn pay-with-btn" @click="formSubmit('paypal')">
                <font-awesome-icon :icon="['fab', 'paypal']" />
                Paypal
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="donation-payment-option" style="display:none;">
      <picture>
        <source
          media="(min-width:650px)"
          srcset="/assets/images/donation/donation-payment-option.webp"
        />
        <source
          media="(min-width:465px)"
          srcset="/assets/images/donation/donate_payment_mobile.webp"
        />
        <img
          src="/assets/images/donation/donate_payment_mobile.webp"
          alt="Flowers"
          style="width:100%;"
        />
      </picture>
    </div>

    <br /><br /><br />
    <!--Special Meal Page end -->
  </div>
</template>

<script>
import swal from "sweetalert";
import axios from "axios";
import console from "console";
const apiResourceUrl = process.env.VUE_APP_APIURL;
const adminPanelUrl = process.env.VUE_APP_ADMINPANELURL;
const appRootUrl = process.env.VUE_APP_ROOTURL;
export default {
  name: "SpecialMeal",
  data() {
    return {
      selectedamount: "",
      show: false,
      comments: "",
      total_no_unit: 0,
      total_amount: 0,
      default_food_item_id: 32,
      default_minimum_unit: 101,
      cus_name: "",
      cus_email: "",
      cus_phone: "",
      currency_bdt: {},
      currency_usd: {},
      food_items: {},
      branch_arr: {},
      inputs: [
        {
          event_date: "",
          branch_id: "",
          food_item_id: 32,
          cost_per_plate: "",
          no_unit: 101,
          minimum_unit: 101,
          subtotal: "",
        },
        {
          event_date: "",
          branch_id: "",
          food_item_id: 32,
          cost_per_plate: "",
          no_unit: 101,
          minimum_unit: 101,
          subtotal: "",
        },
        {
          event_date: "",
          branch_id: "",
          food_item_id: 32,
          cost_per_plate: "",
          no_unit: 101,
          minimum_unit: 101,
          subtotal: "",
        },
      ],
    };
  },
  methods: {
    loadAmount(amount) {
      this.selectedamount = amount;
      console.log(" ");
    },

    donorInfo() {
      if (this.show) {
        this.show = false;
      } else {
        this.show = true;
      }
    },

    addNewRow() {
      this.inputs.push({
        event_date: "",
        branch_id: "",
        food_item_id: this.default_food_item_id,
        cost_per_plate: "",
        no_unit: "",
        minimum_unit: "",
        subtotal: "",
      });
      this.reloadQuanstity();
    },

    openModal() {
      document.getElementById("myModal").style.display = "block";
      document.getElementById("_close_div").style.display = "block";
    },

    closeModal() {
      document.getElementById("myModal").style.display = "none";
    },

    removeRow(index) {
      if (this.inputs.length == 1) {
        swal("Minimum one entry is required !", "", "error");
        return;
      }
      this.inputs.splice(index, 1);
      this.specialMealAmountCalculate();
    },

    reloadQuanstity() {
      this.inputs.forEach((value, index) => {
        this.loadSingleQuantity(index);
      });
    },

    loadSingleQuantity(index) {
      //console.log(this.inputs[index]);
      let branch_id = this.inputs[index].branch_id;
      let foodItem_id = this.inputs[index].food_item_id;
      let foodItemObj = this.food_items[foodItem_id];

      if (branch_id) {
        let branchObj = this.branch_arr[branch_id];
        this.inputs[index].no_unit = branchObj.minimum_quantity;
        //console.log(branchObj);
      }

      this.inputs[index].cost_per_plate = foodItemObj.price;
      if (foodItemObj.minimum_quantity && foodItemObj.minimum_quantity > 0) {
        this.inputs[index].no_unit = foodItemObj.minimum_quantity;
      }
      //console.log(foodItemObj);

      if (
        branch_id == "" &&
        (foodItemObj.minimum_quantity == "" ||
          foodItemObj.minimum_quantity == 0)
      ) {
        this.inputs[index].no_unit = this.default_minimum_unit;
      }

      this.inputs[index].minimum_unit = this.inputs[index].no_unit;
      this.specialMealAmountCalculate();
    },

    specialMealAmountCalculate() {
      const rootObj = this;
      let total_no_unit = 0;
      let total_amount = 0;
      this.inputs.forEach((value, index) => {
        let subtotal = 0;
        if (value.no_unit < value.minimum_unit) {
          swal("Minimum Quantity is can not be changed !", "", "error");
          value.no_unit = value.minimum_unit;
        }
        //Making Subtotal For each And Total of subtotal
        subtotal = value.no_unit * value.cost_per_plate;
        if (subtotal) {
          rootObj.inputs[index].subtotal = subtotal;
          total_amount += subtotal;
        }
        //Making Total of number of unit
        if (value.no_unit) {
          total_no_unit += parseInt(value.no_unit);
        }
      });
      this.total_no_unit = total_no_unit;
      this.total_amount = total_amount;
      this.loadAmount(total_amount);
    },

    formSubmit(payment_type) {
      if (!this.selectedamount) {
        swal("Amount is required !", "", "error");
        return;
      }
      var takenAmount = this.selectedamount;

      const form = new FormData();
      form.append("project_id", "");
      form.append("user_id", localStorage.getItem("userid"));
      form.append("donate_way", 2);
      form.append("cus_name", this.cus_name);
      form.append("cus_email", this.cus_email);
      form.append("cus_phone", this.cus_phone);
      form.append("no_unit", this.total_no_unit);
      form.append("eventArr", JSON.stringify(this.inputs));

      this.closeModal();
      if (payment_type == "ssl") {
        form.append("amount", takenAmount);
        form.append("custom_amount", takenAmount);
        form.append("taken_amount", takenAmount);

        if (takenAmount < this.currency_bdt.min_donate_amount) {
          swal(
            "Minimum Amount is " +
              this.currency_bdt.min_donate_amount +
              " Taka",
            "",
            "error"
          );
          return;
        }

        if (takenAmount > this.currency_bdt.max_donate_amount) {
          swal(
            "Maximum Amount is " +
              this.currency_bdt.max_donate_amount +
              " Taka",
            "",
            "error"
          );
          return;
        }
        this.sslPayment(form);
      }

      if (payment_type == "paypal") {
        var minimumConvertedDonateAmount =
          this.currency_usd.min_donate_amount *
          this.currency_usd.tk_convert_amount;
        var maxmumConvertedDonateAmount =
          this.currency_usd.max_donate_amount *
          this.currency_usd.tk_convert_amount;

        if (takenAmount < minimumConvertedDonateAmount) {
          swal(
            "Minimum Amount is " + minimumConvertedDonateAmount + " Taka",
            "",
            "error"
          );
          return;
        }

        if (takenAmount > maxmumConvertedDonateAmount) {
          swal(
            "Maximum Amount is " + maxmumConvertedDonateAmount + " Taka",
            "",
            "error"
          );
          return;
        }

        var convertedAmount = takenAmount / this.currency_usd.tk_convert_amount;
        form.append("amount", convertedAmount);
        form.append("custom_amount", convertedAmount);
        form.append("taken_amount", convertedAmount);
        this.paypalPayment(form);
      }
    },

    sslPayment(form) {
      delete axios.defaults.headers.common["Authorization"];
      var config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      var childWindow = window.open(appRootUrl + "/loading", "_blank");
      axios
        .post(adminPanelUrl + "/vue_client_pay_with_sslcommerz", form, config)
        .then(function(response) {
          childWindow.location.href = response.data;
        })
        .catch(function(error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          //console.log(alertmessage)
          swal(alertmessage, "", "error");
        });
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("token");
    },

    paypalPayment(form) {
      delete axios.defaults.headers.common["Authorization"];
      var config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      var childWindow = window.open(appRootUrl + "/loading", "_blank");
      axios
        .post(adminPanelUrl + "/vue_client_pay_with_paypal", form, config)
        .then(function(response) {
          childWindow.location.href = response.data;
        })
        .catch(function(error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          //console.log(alertmessage)
          swal(alertmessage, "", "error");
        });
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("token");
    },
  },
  computed: {},
  created() {
    axios.get(apiResourceUrl + "/special-meal").then((response) => {
      //console.log(response.data.result);
      this.food_items = response.data.result.food_items;
      this.branch_arr = response.data.result.branch_arr;
      this.currency_bdt = response.data.result.currency_bdt;
      this.currency_usd = response.data.result.currency_usd;
      this.reloadQuanstity();
    });
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.donate-left-section {
  text-align: justify;
}

pre {
  white-space: pre-wrap;
  /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
  font-family: "Baloo Da 2";
  font-size: 1.125rem;
}

.addmore_btn {
  cursor: pointer;
  font-size: 22px;
  text-align: center;
  padding: 10px 40px;
  margin: 5px auto;
  margin-top: 25px;
  color: #fff !important;
}

.disclaimer {
  background-color: #000000c4;
  color: #fff;
  padding: 10px;
  text-align: left;
  border-radius: 7px;
  margin-top: 60px;
  border-left: 3px solid #faa51a;
}

.mailto {
  color: #faa51a;
}

.displaynone {
  display: none;
}
</style>
