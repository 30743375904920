<template>
  <div>

    <!-- video album header area start -->
    <div class="page_title_bg">
      <div class="container">
        <div class="row">
          <div
            class="col-xl-12 col-xs-12 donation_clearification_header text-center"
          >
            <p class="donation_clearification_title">
              <span><a href="#">GALLERY.</a></span>
              <span class="gallery-link">VIDEO Gallery </span
              ><span><a href="#">.PRESS</a></span>
            </p>
            <p class="donation_clearification_text">
              Home / Media / {{category_name}}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- video album header area end -->

    <!-- video album area start -->
    <div class="container">
      <div class="row video-gallery">
        
        <div class="col-xl-4 col-md-6 col-sm-6" v-for="list in vedioList" :key="list.id">
          <iframe :src="list.video_link" allowfullscreen ></iframe>
        </div>

      </div>
    </div>
    <!-- video album area end -->

  </div>
</template>

<script>
  import axios from 'axios'
  const apiResourceUrl = process.env.VUE_APP_APIURL;
  export default {
    name: 'videoDetails',
    data(){
      return{
        category_name : '',
        vedioList : {}
      }
    },
    
    methods: {
  
    },

    computed: {
      
    },
    async created(){
      var CategoryID = this.$route.params.id;
      //console.log(CategoryID)
      await axios.get(apiResourceUrl+'/videos/'+CategoryID).then((response) => {
          console.log(response.data.videos)
          this.category_name = response.data.category_name
          this.vedioList = response.data.videos
      })
    },
    mounted(){

    },
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
