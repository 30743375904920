<template>
  <div id="body-section">
    <div class="container">
      <div class="row doner-profile">
        <div class="col-md-3">
          <div class="profile-left">
            <router-link :to="{ name: 'Donor' }" class="yellow-btn">
              Home
            </router-link>
            <router-link :to="{ name: 'DonorProfile' }" class="green-btn">
              Doner profile
            </router-link>
            <router-link :to="{ name: 'DonorScholarship' }" class="yellow-btn">
              Students
            </router-link>
            <router-link :to="{ name: 'SpecialMealReport' }" class="yellow-btn">
              Special Meals
            </router-link>
          </div>
        </div>
        <div class="col-md-9 profile-right">
          <div class="row doner-form">
            <form @submit="formSubmit" enctype="multipart/form-data">
              <div class="row input_field">
                <div class="col-md-6 _picture">
                  <label for="profile_image">Your Picture</label>
                  <input
                    type="file"
                    id="profile_image"
                    name="profile_image"
                    ref="file"
                    @change="onFileSelected"
                  />
                  <img :src="image" class="img-responsive" />
                </div>
                <div class="col-md-6">
                  <label for="email">Email</label>
                  <input
                    class="text_area"
                    type="text"
                    placeholder="Enter Your Email"
                    v-model="email"
                  />

                  <label for="name">Name*</label>
                  <input
                    class="text_area"
                    type="text"
                    placeholder="Enter Your Name"
                    v-model="name"
                  />

                  <label for="contact-no">Contact No</label>
                  <input
                    class="text_area"
                    type="text"
                    placeholder="Enter Your Contact Number"
                    v-model="contact_no"
                  />

                  <label for="blood_group_id">Blood Group</label>
                  <select
                    name="blood_group_id"
                    id="blood_group_id"
                    v-model="blood_group_id"
                    required
                  >
                    <option value="">Select Blood Group</option>
                    <option
                      v-for="bloodGroupData in bloodGroups"
                      :key="bloodGroupData.id"
                      :value="bloodGroupData.id"
                    >
                      {{ bloodGroupData.name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-6">
                  <label for="occupation" class="occupation">Occupation</label
                  ><br />
                  <input
                    class="text_area"
                    type="text"
                    placeholder="Enter Your Occupation"
                    v-model="occupation"
                  />
                </div>
                <div class="col-md-6">
                  <label for="interest">Interest</label>
                  <input
                    class="text_area"
                    type="text"
                    v-model="interest"
                    placeholder="Enter Your Interest"
                  />
                </div>

                <div class="col-md-12">
                  <label for="address">Address</label>
                  <input
                    class="text_area"
                    type="text"
                    v-model="address"
                    placeholder="Enter Your Address"
                  />

                  <input type="hidden" v-model="image_location" />

                  <div class="submit-btn">
                    <a href="#" @click="formSubmit" class="green-btn">Update</a>
                    <router-link :to="{ name: 'Donor' }" class="green-btn">
                      Cancel
                    </router-link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
import axios from "axios";
const apiResourceUrl = process.env.VUE_APP_APIURL;
const adminPanelUrl = process.env.VUE_APP_ADMINPANELURL;
export default {
  data() {
    return {
      email: "",
      name: "",
      contact_no: "",
      occupation: "",
      interest: "",
      address: "",
      blood_group_id: "",
      bloodGroups: {},
      image: "",
      profile_image: "",
      image_location: "",
    };
  },

  name: "DonorProfile",

  async created() {
    const dataObject = this;
    dataObject.image_location = localStorage.getItem("uploadedProfileImage");

    await axios
      .get(apiResourceUrl + "/donor-profile")
      .then(function(response) {
        //console.log(response.data);
        dataObject.email = response.data.user.email;
        dataObject.name = response.data.user.name;
        dataObject.bloodGroups = response.data.bloodGroups;

        dataObject.contact_no = response.data.user.profile_data_contact_no;
        dataObject.occupation = response.data.user.profile_data_occupation;
        dataObject.interest = response.data.user.profile_data_interest;
        dataObject.address = response.data.user.profile_data_address;
        dataObject.blood_group_id =
          response.data.user.profile_data_blood_group_id;

        dataObject.image = response.data.user.profile_image;
      })
      .catch(function(error) {
        let alertmessage = "";
        for (var prop in error.response.data) {
          alertmessage += " " + error.response.data[prop];
        }
        swal(alertmessage, "", "error");
        //console.log(alertmessage)
      });
  },

  methods: {
    formSubmit(e) {
      e.preventDefault();

      const form = new FormData();
      form.append("profile_image", this.profile_image);
      form.append("email", this.email);
      form.append("name", this.name);
      form.append("contact_no", this.contact_no);
      form.append("occupation", this.occupation);
      form.append("interest", this.interest);
      form.append("address", this.address);
      form.append("blood_group_id", this.blood_group_id);
      form.append("image_location", this.image_location);

      var config = {
        headers: { "Content-Type": "multipart/form-data" },
      };

      axios
        .post(apiResourceUrl + "/update-donor-profile", form, config)
        .then(function(response) {
          //console.log(response.data.message);
          swal(response.data.message, "", "success");
          localStorage.setItem("uploadedProfileImage", "");
        })
        .catch(function(error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          //console.log(alertmessage)
          //alert(alertmessage)
          swal(alertmessage, "", "error");
        });
    },

    onFileSelected(event) {
      //console.log(event.target.files[0]);
      this.profile_image = event.target.files[0];
      this.image = URL.createObjectURL(this.profile_image);
      this.uploadFile();
    },

    uploadFile() {
      delete axios.defaults.headers.common["Authorization"];

      var newObject = this;
      const form = new FormData();
      form.append("upload_for", "profile_image");
      form.append("image", this.profile_image);
      form.append("userid", localStorage.getItem("userid"));
      form.append(
        "uploadedProfileImage",
        localStorage.getItem("uploadedProfileImage")
      );

      var config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      axios
        .post(adminPanelUrl + "/upload-attachments-from-vue", form, config)
        .then(function(response) {
          //console.log(response.data);
          newObject.image_location = response.data;
          localStorage.setItem("uploadedProfileImage", response.data);
        })
        .catch(function(error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          //console.log(alertmessage)
          swal(alertmessage, "", "error");
        });
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("token");
    },
  },

  computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
