<template>
  <div class="subscriber-section">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-12">
          <p class="subscription-title">
            For News, Updates and Promotional Events
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-12">
          <form
            @submit="formSubscribeNow"
            class="subscribtion-form text-center"
          >
            <input
              type="email"
              v-model="email"
              class="subscribe-input"
              required=""
              placeholder="Enter your valid email address"
            />
            <button class="subscribe-btn">SUBSCRIBE</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
import axios from "axios";
const apiResourceUrl = process.env.VUE_APP_APIURL;
export default {
  name: "SubscribeNow",
  data() {
    return {
      email: "",
    };
  },
  computed: {},
  methods: {
    formSubscribeNow(e) {
      e.preventDefault();
      var formObject = this;
      //console.log(formObject);
      const form = new FormData();
      form.append("email", this.email);

      var config = {
        headers: { "Content-Type": "multipart/form-data" },
      };

      axios
        .post(apiResourceUrl + "/subscribe-now", form, config)
        .then(function(response) {
          //console.log(response.data.message);
          //alert(response.data.message)
          swal(response.data.message, "", "success");
          formObject.resetForm();
        })
        .catch(function(error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += error.response.data[prop];
          }
          //console.log(alertmessage)
          swal(alertmessage, "", "error");
          //alert(alertmessage)
        });
    },

    resetForm() {
      this.email = "";
    },
  },
  created() {},
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
