<template>
  <div>
    <!-- Start -->


    <!-- contact header area start -->
    <div class="page_title_bg">
      <div class="container">
        <div class="row">
            <div class="col-xl-12 col-xs-12 donation_clearification_header text-center">
                <p class="donation_clearification_title">FREQUENTLY ASKED QUESTIONS</p>
                <p class="white-bar"></p>
                <p class="donation_clearification_text">Home / Faq</p>
            </div>
          </div>
        </div>
    </div>
    <!-- contact header area end -->


    <!--Faq Section start-->
    <div class="container">
       <div class="faq-section">
          <div class="row">

             <div class="col-md-6">
                <div v-for="(list, index) in faqList" :key="list.id">
                  <div class="single-question-section" v-if="index%2==0">

                     <a class="single-question" data-bs-toggle="collapse" :href="'#collapse_'+index" role="button"> {{list.question}} <span style="float:right" ><i class="fas fa-chevron-down"></i></span></a>
                     <div class="collapse" :id="'collapse_'+index">
                        <b>Answer : </b><br> {{list.answer}}
                     </div>
                  </div>
                </div>
             </div>

             <div class="col-md-6">
                <div v-for="(list, index) in faqList" :key="list.id">
                  <div class="single-question-section" v-if="index%2!=0">

                     <a class="single-question" data-bs-toggle="collapse" :href="'#collapse_'+index" role="button"> {{list.question}} <span style="float:right" ><i class="fas fa-chevron-down"></i></span></a>
                     <div class="collapse" :id="'collapse_'+index">
                        <b>Answer : </b><br> {{list.answer}}
                     </div>
                  </div>
                </div>
             </div>

             <div class="col-md-12 text-center">
                <router-link :to="{name: 'Contact'}" class="btn signIn_btn contactbtn">
                   CONTACT US
                </router-link>
             </div>
          </div>
          <!--row section-->
       </div>
    </div>
    <!--Container section-->


    <!-- End -->
  </div>
</template>

<script>

  import axios from 'axios'
  const apiResourceUrl = process.env.VUE_APP_APIURL;
  export default {
    name: 'Faq',
    data(){
      return{
        faqList : {}
      }
    },
    computed: {
  
    },
    async created(){
      await axios.get(apiResourceUrl+'/faqs').then((response) => {
        this.faqList = response.data;
        //console.log(response.data);
      })
    },
    mounted(){
    
    }
  }

</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
