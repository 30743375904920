<template>
  <div id="body-section">
    <div class="container">
      <div class="row doner-profile">
        <div class="col-md-3">
          <div class="profile-left">
            <router-link :to="{ name: 'Donor' }" class="yellow-btn">
              Home
            </router-link>
            <router-link :to="{ name: 'DonorProfile' }" class="yellow-btn">
              Doner profile
            </router-link>

            <router-link :to="{ name: 'DonorScholarship' }" class="green-btn">
              Students
            </router-link>
            <router-link :to="{ name: 'SpecialMealReport' }" class="yellow-btn">
              Special Meals
            </router-link>
          </div>
        </div>

        <div class="col-md-9 student">
          <div class="right">
            <div class="right-top">
              <div class="row">
                <div class="profile col-md-5">
                  <img :src="student.student_image" alt="" />
                  <p>{{ student.name }}</p>
                </div>
                <div class="col-md-7">
                  <iframe :src="student.student_video" allowfullscreen></iframe>
                </div>
              </div>
            </div>
            <div class="right-bottom">
              <div class="-button-grp d-flex justify-content-between">
                <button
                  id="std-btn"
                  :class="{ 'active-btn': isAddClass1 }"
                  @click="showTab(1)"
                >
                  Student information
                </button>
                <button
                  id="pay-btn"
                  :class="{ 'active-btn': isAddClass2 }"
                  @click="showTab(2)"
                >
                  payment information
                </button>
                <button
                  id="notice-btn"
                  :class="{ 'active-btn': isAddClass3 }"
                  @click="showTab(3)"
                >
                  Notice
                </button>
              </div>
              <div
                id="std-info"
                class="std-info row justify-content-between"
                v-if="show == 1"
              >
                <div class="_info d-flex">
                  <img src="/assets/images/icon/success.png" alt="" />
                  <p>
                    Father: <span>{{ student.father_name }}</span>
                  </p>
                </div>
                <div class="_info d-flex">
                  <img src="/assets/images/icon/success.png" alt="" />
                  <p>
                    Mother: <span>{{ student.mother_name }}</span>
                  </p>
                </div>
                <div class="_info d-flex">
                  <img src="/assets/images/icon/success.png" alt="" />
                  <p>
                    Father Occ: <span>{{ student.father_occupation }}</span>
                  </p>
                </div>
                <div class="_info d-flex">
                  <img src="/assets/images/icon/success.png" alt="" />
                  <p>
                    Mother Occ: <span>{{ student.mother_occupation }}</span>
                  </p>
                </div>
                <div class="_info d-flex">
                  <img src="/assets/images/icon/success.png" alt="" />
                  &nbsp;
                  <p class="bg-gray">
                    Father : <span v-if="student.is_father == 1">Alive</span>
                    <span v-else>Died</span>
                  </p>
                </div>
                <div class="_info d-flex">
                  <img src="/assets/images/icon/success.png" alt="" />
                  &nbsp;
                  <p class="bg-gray">
                    Mother : <span v-if="student.is_mother == 1">Alive</span>
                    <span v-else>Died</span>
                  </p>
                </div>
                <div class="_info d-flex">
                  <img src="/assets/images/icon/success.png" alt="" />
                  <p>
                    Tribe Name: <span>{{ student.tribe_name }}</span>
                  </p>
                </div>
                <div class="_info d-flex">
                  <img src="/assets/images/icon/success.png" alt="" />
                  <p>
                    Orphange: <span> {{ student.orphange_name }}</span>
                  </p>
                </div>
                <div class="_info d-flex">
                  <img src="/assets/images/icon/success.png" alt="" />
                  <p>
                    Admission Date: <span> {{ student.admission_date }}</span>
                  </p>
                </div>
                <div class="_info d-flex">
                  <img src="/assets/images/icon/success.png" alt="" />
                  <p>
                    Orphange Reason:
                    <span>{{ student.reason_for_orphan }}</span>
                  </p>
                </div>
                <div class="_info d-flex">
                  <img src="/assets/images/icon/success.png" alt="" />
                  <p>
                    Class Roll: <span>{{ student.class_roll }}</span>
                  </p>
                </div>
                <div class="_info d-flex">
                  <img src="/assets/images/icon/success.png" alt="" />
                  <p>
                    Contact No: <span>{{ student.contact_no }}</span>
                  </p>
                </div>
                <div class="_info d-flex">
                  <img src="/assets/images/icon/success.png" alt="" />
                  <p>
                    Class: <span>{{ student.student_class_name }}</span>
                  </p>
                </div>
                <div class="_info d-flex">
                  <img src="/assets/images/icon/success.png" alt="" />
                  <p>
                    Present Address: <span>{{ student.present_address }}</span>
                  </p>
                </div>
                <div class="_info d-flex">
                  <img src="/assets/images/icon/success.png" alt="" />
                  <p>
                    Institute: <span>{{ student.institute }}</span>
                  </p>
                </div>
                <div class="_info d-flex">
                  <img src="/assets/images/icon/success.png" alt="" />
                  <p>
                    Permanent Address:
                    <span>{{ student.permanent_address }}</span>
                  </p>
                </div>
                <div class="_info d-flex">
                  <img src="/assets/images/icon/success.png" alt="" />
                  <p>
                    Religion: <span> {{ student.religion_name }}</span>
                  </p>
                </div>
                <div class="_info d-flex">
                  <img src="/assets/images/icon/success.png" alt="" />
                  <p>
                    Gardian Name: <span>{{ student.gurdian_name }}</span>
                  </p>
                </div>
                <div class="_info d-flex">
                  <img src="/assets/images/icon/success.png" alt="" />
                  <p>
                    Gender : <span>{{ student.gender_name }}</span>
                  </p>
                </div>
                <div class="_info d-flex">
                  <img src="/assets/images/icon/success.png" alt="" />
                  <p>
                    Gardian Relation:
                    <span>{{ student.gurdian_relation }}</span>
                  </p>
                </div>
                <div class="_info d-flex">
                  <img src="/assets/images/icon/success.png" alt="" />
                  <p>
                    Height: <span>{{ student.height }}</span>
                  </p>
                </div>
                <div class="_info d-flex">
                  <img src="/assets/images/icon/success.png" alt="" />
                  <p>
                    Siblings: <span>{{ student.siblings }}</span>
                  </p>
                </div>
                <div class="_info d-flex">
                  <img src="/assets/images/icon/success.png" alt="" />
                  <p>
                    Weight: <span>{{ student.weight }}</span>
                  </p>
                </div>
                <div class="_info d-flex">
                  <img src="/assets/images/icon/success.png" alt="" />
                  <p>
                    Illness: <span>{{ student.illness }}</span>
                  </p>
                </div>
                <div class="_info d-flex">
                  <img src="/assets/images/icon/success.png" alt="" />
                  <p>
                    Blood Group: <span>{{ student.blood_name }}</span>
                  </p>
                </div>
              </div>
              <div id="pay-info" class="pay-info row " v-if="show == 2">
                <table>
                  <tr>
                    <th>Date</th>
                    <th>Description</th>
                    <th>Payment Method</th>
                    <th>Currency</th>
                    <th>Amount</th>
                    <th>Session</th>
                  </tr>
                  <tr v-for="item in donation_details" :key="item.id">
                    <td>{{ item.donate_date }}</td>
                    <td>{{ item.payment_description }}</td>
                    <td v-if="item.payment_method == 1" class="_button_">
                      <button>Manual</button>
                    </td>
                    <td v-else-if="item.payment_method == 2" class="_button_">
                      <button>Paypal</button>
                    </td>
                    <td v-else class="_button_">
                      <button>SSLCommerze</button>
                    </td>
                    <td>Taka</td>
                    <td>{{ item.tk_amount }}</td>
                    <th>{{ item.scholarship.year }}</th>
                  </tr>
                </table>
              </div>
              <div id="notice" class="notice row student" v-if="show == 3">
                <table>
                  <tr>
                    <th>Topic</th>
                    <th>Date</th>
                    <th>Description</th>
                    <th>Attachment</th>
                  </tr>
                  <tr v-for="list in student.details" :key="list.id">
                    <td>{{ list.topic }}</td>
                    <td>{{ list.date }}</td>
                    <td>{{ list.description }}</td>
                    <td v-if="list.attachment != ''" align="center">
                      <button class="download_btn">
                        <img
                          class="_yellow"
                          src="/assets/images/icon/download-yellow.webp"
                          alt=""
                        />
                        <!-- <img class="_green" src="/assets/images/icon/download-green.webp" alt=""> -->
                      </button>
                    </td>
                    <td v-else align="center"></td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
const apiResourceUrl = process.env.VUE_APP_APIURL;

export default {
  name: "DonorScholarshipDetails",
  data() {
    return {
      show: 1,
      student: "",
      donation_details: {},
      isAddClass1: true,
      isAddClass2: false,
      isAddClass3: false,
    };
  },

  async created() {
    var id = this.$route.params.id;
    var scholarship_id = this.$route.params.scholarship_id;

    var newObject = this;

    await axios
      .get(apiResourceUrl + "/donors_scholarship/" + id + "/" + scholarship_id)
      .then(function(response) {
        //console.log(response.data);
        newObject.student = response.data.student;
        newObject.donation_details = response.data.donation_details;
      })
      .catch(function(error) {
        let alertmessage = "";
        for (var prop in error.response.data) {
          alertmessage += " " + error.response.data[prop];
        }
        console.log(alertmessage);
      });
  },

  methods: {
    showTab(tab_id) {
      this.show = tab_id;
      this.isAddClass1 = false;
      this.isAddClass2 = false;
      this.isAddClass3 = false;
      if (tab_id == 1) {
        this.isAddClass1 = true;
      }
      if (tab_id == 2) {
        this.isAddClass2 = true;
      }
      if (tab_id == 3) {
        this.isAddClass3 = true;
      }
    },
  },

  computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
