<template>
  <div class="our-mission">
       <div class="container">
          <div class="row">
             <div class="col-md-12">
                <p class="text-center mission-heading">OUR MISSION</p>
                <p class="text-center mission-sub-heading pb-2">Provide support to underprivileged section of the society specially extreme poor, homeless and 
                   orphan children through quality education, nourishment & shelter to make
                   them resources for the nation.
                </p>
             </div>
          </div>
          <div class="row">
             <div class="col-md-4 ">
                <div class="mission-single-block margin_padding-2">
                   <div class="mission-single-image">
                      <img src="/assets/images/icon/spoun.webp" alt="food" />
                   </div>
                   <p class="mission-single-heading"> FOOD</p>
                   <p class="mission-single-text"> We want to inspire a nation with food. Best
                      relation can be created through sharing food.
                   </p>
                </div>
             </div>
             <div class="col-md-4">
                <div class="mission-single-block margin_padding-2">
                   <div class="mission-single-image">
                      <img src="/assets/images/icon/graduate.webp" alt="education" />
                   </div>
                   <p class="mission-single-heading"> EDUCATION</p>
                   <p class="mission-single-text"> To become a national treasure by offering free education to children and orphans.</p>
                </div>
             </div>
             <div class="col-md-4 ">
                <div class="mission-single-block margin_padding-2">
                   <div class="mission-single-image">
                      <img src="/assets/images/icon/medikit.webp" alt="treatment" />
                   </div>
                   <p class="mission-single-heading"> TREATMENT</p>
                   <p class="mission-single-text"> To deliver free medicines and health care to underprivileged people.</p>
                </div>
             </div>
          </div>
       </div>
    </div>
</template>

<script>
  export default {
    name: 'OurMission'
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
