<template>
  <div class="page-section second_menu">
       <div class="container">
          <div class="row">
             <div class="col-md-2 col-6">
                <ul class="page-menu">
                   <li class="explore_projects_hero">EXPLORE PROJECTS:</li>
                </ul>
             </div>

             <div class="col-md-2 col-6" v-for="item in projectList" :key="item.id">
                <ul class="page-menu">
                   <li>
                      <router-link :to="{name: 'Donate', params:{id:item.id}}" v-if="item.name.length<23">
                          {{ item.name }}
                      </router-link>

                      <router-link :to="{name: 'Donate', params:{id:item.id}}" v-else>
                          {{ item.name.substring(0,23)+" . ." }}
                      </router-link>
                   </li>
                </ul>
             </div>

             <div class="col-md-2 col-6">
                <ul class="page-menu">
                  <li>
                    <router-link :to="{ name: 'ExploreProject'}">SEE MORE &#x27A4;</router-link>
                  </li>
                </ul>
             </div>
          </div>
       </div>
    </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  //import axios from 'axios'
  //const apiResourceUrl = process.env.VUE_APP_APIURL;
  export default {
    name: 'HeroAreaBottomMenu',
    data(){
      return{
        //projectList : {}
      }
    },
    computed: {
      ...mapGetters({
        projectList : 'homeAreaBottomProjectData'
      })
    },
    created(){
      // axios.get(apiResourceUrl+'/projects').then((response) => {
      //   this.projectList = (response.data.projects).slice(5, 9);
      //   //console.log(response.data.projects);
      // })
    },
    mounted(){
    
    }
  }
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
