<template>
    <div class="container partner" id="partner">
       <div class="row">
          <div class="col-md-12 ">
             <p class="corporate-heading text-center">OUR INITIATIVES</p>
          </div>
       </div>
       <div class="row partner-logo">
         <a class="col-4 col-xl-2" href="https://www.facebook.com/sombolbd" target="_blank">
          <img src="/assets/images/partner/1.webp"> 
         </a>
         <a class="col-4 col-xl-2" href="https://www.facebook.com/1Tk.healthcare" target="_blank">
          <img src="/assets/images/partner/2.webp"> 
         </a>
         <a class="col-4 col-xl-2" href="https://www.facebook.com/1Tk.Meal" target="_blank">
          <img src="/assets/images/partner/3.webp"> 
         </a>
         <a class="col-4 col-xl-2" href="https://www.facebook.com/bidyanondo.publication" target="_blank">
          <img src="/assets/images/partner/4.webp"> 
         </a>
         <a class="col-4 col-xl-2" href="https://www.facebook.com/Save.Refugees" target="_blank">
          <img src="/assets/images/partner/5.webp"> 
         </a>
         <a class="col-4 col-xl-2" href="https://www.facebook.com/Bidyanondo.Orphanage" target="_blank">
          <img src="/assets/images/partner/6.webp"> 
         </a>
       </div>
    </div>
</template>

<script>
  export default {
    name: 'CorporatePartners'
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
