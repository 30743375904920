<template>
  <div>
     <!-- hero area start -->
    <div class="page_title_bg">
        <div class="container">
            <div class="row">
                <div class="col-xl-12 col-xs-12 donation_clearification_header text-center">
                    <p class="donation_clearification_title">forgot password</p>
                    <p class="white-bar"></p>
                    <p class="donation_clearification_text">Home / Forgot Password</p>
                </div>
            </div>
        </div>
        </div>
        <!-- hero area -->


<!--forget password section start-->
<div class="all_login_bg  forget_password">
<div class="container">
    <div class="row">
        <div class="col-xl-12 text-center mobile_p0  d-flex justify-content-center">
            <form class="form_block request_block">
                <input type="text" placeholder="Enter Your Email" class="form-control forget_input_email" v-model="email">
                <button class="btn signIn_btn contactbtn" @click="formSubmit">SEND</button>
            </form>
        </div>
    </div>
</div>

</div>

<!--forget password section end-->
  </div>
</template>

<script>
  import axios from 'axios'
  import swal from 'sweetalert';
  const apiResourceUrl = process.env.VUE_APP_APIURL;
  export default {
    name: 'ForgotPassword',
    data() {
        return {
          email: ''
        };
    },
    methods: {
        formSubmit(e) {
            e.preventDefault();
            var formObject = this;

            if(!this.email){
                swal('Email is required.', '', "error");
                return;
            }

            axios.post(apiResourceUrl+'/reset-password', {
                email: this.email
            })
            .then(function (response) {
                swal(response.data.message, '', "success");
                formObject.email = ''
            })
            .catch(function (error) {
                let alertmessage = "";
                for (var prop in error.response.data) {
                    alertmessage += error.response.data[prop]
                }
                //console.log(alertmessage)
                swal(alertmessage, '', "error");
                //alert(alertmessage)
            });
        }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
