import { createWebHistory, createRouter } from 'vue-router';
import Home from '../components/Home.vue'
import About from '../components/About.vue'
import Media from '../components/Media.vue'
import JoinUs from '../components/JoinUs.vue'
import SignIn from '../components/SignIn.vue'
import Faq from '../components/Faq.vue'
import Contact from '../components/Contact.vue'
import Gallery from '../components/Gallery.vue'
import ExploreProject from '../components/ExploreProject.vue'
import ForgotPassword from '../components/ForgotPassword.vue'
import Donate from '../components/Donate.vue'
import BankInfo from '../components/BankInfo.vue'
import Video from '../components/Video.vue'
import videoDetails from '../components/videoDetails.vue'
import FloodRelief from '../components/FloodRelief.vue'
import DonationClarification from '../components/DonationClarification.vue'
import Campaign from '../components/Campaign.vue'
import Branch from '../components/Branch.vue'
import AuditReport from '../components/AuditReport.vue'
import BangladeshiVolunteer from '../components/BangladeshiVolunteer.vue'
import InternationalVolunteer from '../components/InternationalVolunteer.vue'
import Sponsor from '../components/Sponsor.vue'
import SponsorDetails from '../components/SponsorDetails.vue'
import Donor from '../components/Donor.vue'
import DonorProfile from '../components/DonorProfile.vue'
import DonorScholarship from '../components/DonorScholarship.vue'
import DonorScholarshipDetails from '../components/DonorScholarshipDetails.vue'
import CampaignDetails from '../components/CampaignDetails.vue'
import Failed from '../components/Failed.vue'
import Success from '../components/Success.vue'
import GeneralDonate from '../components/GeneralDonate.vue'
import Loading from '../components/Loading.vue'
import SponsorNow from '../components/SponsorNow.vue'
import SpecialMeal from '../components/SpecialMeal.vue'
import DonorSpecialMeal from '../components/DonorSpecialMeal.vue'
import DonorScholarshipFeedBack from '../components/DonorScholarshipFeedBack.vue'


function guardMyroute(to, from, next)
{
 	var isAuthenticated= false;

	if(localStorage.getItem('token')){
		//console.log(localStorage.getItem('token'));
  		isAuthenticated = true;
	}
 	else{
 		//console.log('No Token')
  		isAuthenticated= false;
 	}
 
 	if(isAuthenticated) {
  		next(); // allow to enter route
 	} else{
  		next('/signin'); // go to '/login';
 	}
}

const routes = [
	{
		path: '/',
		component: Home,
		name: 'Home'
	},
	{
		path: '/about',
		component: About,
		name: 'About'
	},
	{
		path: '/media',
		component: Media,
		name: 'Media'
	},
	{
		path: '/signup',
		component: JoinUs,
		name: 'JoinUs'
	},
	{
		path: '/signin',
		component: SignIn,
		name: 'SignIn'
	},
	{
		path: '/faq',
		component: Faq,
		name: 'Faq'
	},
	{
		path: '/contact',
		component: Contact,
		name: 'Contact'
	},
	{
		path: '/gallery',
		component: Gallery,
		name: 'Gallery'
	},
	{
		path: '/projects',
		component: ExploreProject,
		name: 'ExploreProject'
	},
	{
		path: '/forgot-password',
		component: ForgotPassword,
		name: 'ForgotPassword'
	},
	{
		path: '/projects/:id',
		component: Donate,
		name: 'Donate'
	},
	{
		path: '/sponsornow/:id',
		component: SponsorNow,
		name: 'SponsorNow'
	},
	{
		path: '/donate',
		component: GeneralDonate,
		name: 'GeneralDonate'
	},
	{
		path: '/bank-information',
		component: BankInfo,
		name: 'BankInfo'
	},
	{
		path: '/video',
		component: Video,
		name: 'Video'
	},
	{
		path: '/video/:id',
		component: videoDetails,
		name: 'videoDetails',
		props: true
	},
	{
		path: '/floodrelief',
		component: FloodRelief,
		name: 'FloodRelief'
	},
	{
		path: '/donation-clarification',
		component: DonationClarification,
		name: 'DonationClarification'
	},
	{
		path: '/campaigns',
		component: Campaign,
		name: 'Campaign'
	},
	{
		path: '/campaign/:id',
		component: CampaignDetails,
		name: 'CampaignDetails'
	},
	{
		path: '/branch',
		component: Branch,
		name: 'Branch'
	},
	{
		path: '/audit-report',
		component: AuditReport,
		name: 'AuditReport'
	},
	{
		path: '/bangladeshi-volunteer',
		component: BangladeshiVolunteer,
		name: 'BangladeshiVolunteer'
	},
	{
		path: '/international-volunteer',
		component: InternationalVolunteer,
		name: 'InternationalVolunteer'
	},
	{
		path: '/sponsor',
		component: Sponsor,
		name: 'Sponsor'
	},
	{
		path: '/special-meal',
		component: SpecialMeal,
		name: 'SpecialMeal'
	},
	{
		path: '/special-meal-report',
		component: DonorSpecialMeal,
		name: 'SpecialMealReport'
	},
	{
		path: '/special-meal-feedback/:id',
		component: DonorScholarshipFeedBack,
		name: 'DonorScholarshipFeedBack',
		props: true
	},
	{
		path: '/sponsor-details/:id',
		component: SponsorDetails,
		name: 'SponsorDetails',
		props: true
	},
	{
		path: '/donor',
		component: Donor,
		name: 'Donor',
		beforeEnter : guardMyroute
	},
	{
		path: '/donor-profile',
		component: DonorProfile,
		name: 'DonorProfile',
		beforeEnter : guardMyroute
	},
	{
		path: '/donor-scholarship/:id/:scholarship_id',
		component: DonorScholarshipDetails,
		name: 'DonorScholarshipDetails',
		beforeEnter : guardMyroute
	},
	{
		path: '/donor-scholarship',
		component: DonorScholarship,
		name: 'DonorScholarship',
		beforeEnter : guardMyroute
	},
	{
		path: '/failed',
		component: Failed,
		name: 'Failed'
	},
	{
		path: '/success',
		component: Success,
		name: 'Success'
	},
	{
		path: '/loading',
		component: Loading,
		name: 'Loading'
	},
	
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach(function	 (){
  window.scrollTo(0, 0)
})

export default router;