<template>
  <div>
    <!-- donation clearification header area start -->
    <div class="page_title_bg">
      <div class="container">
        <div class="row">
          <div
            class="col-xl-12 col-xs-12 donation_clearification_header text-center"
          >
            <p class="donation_clearification_title">Donation Clarification</p>
            <p class="white-bar"></p>
            <p class="donation_clearification_text">
              Home / Donation Clarification
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- donation clearification header area end -->

    <!-- donation clearification form area end -->
    <div class="all_login_bg donation_clearification_block">
      <div class="container">
        <br />
        <br />
        <div class="row" style="display: none;">
          <div class="col-md-11 col-xs-12 offset-md-1">
            <div class="select_area _international">
              <router-link class="bangladesh" to="/donate"
                >Genaral Donation</router-link
              >
              <router-link
                class="international international_shadow"
                to="/donation-clarification"
                >Donation Clarification</router-link
              >
            </div>
          </div>
        </div>

        <form @submit="formSubmit" enctype="multipart/form-data">
          <div class="row">
            <div class="col-xl-12 col-xs-12 pl-4 pr-4">
              <div class="row">
                <div class="col-xl-6 col-xs-6 pl-4 pr-4">
                  <div class="form-group">
                    <label><span class="required-items">Amount *</span></label>
                    <input
                      type="text"
                      v-model="amount"
                      class="form-control from-control-custom"
                      placeholder="Enter Amount"
                      alt="amount"
                    />
                  </div>
                </div>

                <div class="col-xl-6 col-xs-6 pl-4 pr-4">
                  <div class="form-group">
                    <label
                      ><span class="required-items">Currency *</span></label
                    >
                    <select
                      class="form-control from-control-custom"
                      v-model="currency_id"
                    >
                      <option
                        v-for="currency in currency_lists"
                        :key="currency.id"
                        :value="currency.id"
                      >
                        {{ currency.currency_name }} ({{
                          currency.currency_code
                        }})
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-6 col-xs-6 pl-4 pr-4">
                  <div class="form-group">
                    <label><span class="required-items">Email *</span></label>
                    <input
                      type="text"
                      class="form-control from-control-custom"
                      placeholder="Enter Your Email"
                      v-model="donor_email"
                      alt="email"
                    />
                  </div>
                </div>

                <div class="col-xl-6 col-xs-6 pl-4 pr-4">
                  <div class="form-group">
                    <label><span class="required-items">Date *</span></label>
                    <input
                      type="date"
                      class="form-control from-control-custom"
                      placeholder="Enter date"
                      v-model="date"
                      alt="date"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-6 col-xs-6 pl-4 pr-4">
                  <div class="form-group">
                    <label
                      ><span class="required-items"
                        >Donation Reason *</span
                      ></label
                    >
                    <select
                      class="form-control from-control-custom"
                      v-model="sector_id"
                    >
                      <option
                        v-for="sectorData in sectors"
                        :key="sectorData.id"
                        :value="sectorData.id"
                      >
                        {{ sectorData.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-xl-6 col-xs-6 pl-4 pr-4">
                  <div class="form-group">
                    <label
                      ><span class="required-items"
                        >Payment Channel *</span
                      ></label
                    >
                    <select
                      class="form-control from-control-custom"
                      v-model="payment_method_id"
                      @change="filterPayeeAccountNo()"
                    >
                      <option
                        v-for="paymentMethodData in payment_methods"
                        :key="paymentMethodData.id"
                        :value="paymentMethodData.id"
                      >
                        {{ paymentMethodData.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="row" v-if="account_div_seen">
                <div class="col-xl-6 col-xs-6 pl-4 pr-4">
                  <div class="form-group">
                    <label
                      ><span class="required-items">{{
                        l_payee_account_no
                      }}</span></label
                    >
                    <select
                      class="form-control from-control-custom"
                      v-model="payee_account_no"
                    >
                      <option
                        v-for="loaded_account_data in loaded_account"
                        :key="loaded_account_data.id"
                        :value="loaded_account_data.name"
                      >
                        {{ loaded_account_data.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div
                  class="col-xl-6 col-xs-6 pl-4 pr-4"
                  v-if="payer_account_div_seen"
                >
                  <div class="form-group">
                    <label
                      ><span class="required-items">{{
                        l_payer_account_no
                      }}</span></label
                    >
                    <input
                      type="text"
                      class="form-control from-control-custom"
                      placeholder="Enter Your bKash Number"
                      v-model="payer_account_no"
                      alt="bKash"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-6 col-xs-6 pl-4 pr-4">
                  <div class="form-group">
                    <label><span class="form-items">Name</span></label>
                    <input
                      type="text"
                      class="form-control from-control-custom"
                      placeholder="Enter Your Name"
                      v-model="donor_name"
                      alt="name"
                    />
                  </div>
                </div>
                <div class="col-xl-6 col-xs-6 pl-4 pr-4">
                  <div class="form-group">
                    <label><span class="form-items">Attachment</span></label>
                    <input
                      type="file"
                      class="form-control from-control-custom"
                      placeholder="Enter Your Name"
                      alt="Name"
                      ref="file"
                      @change="onFileSelected"
                    />
                    <small class="form-text text-muted"
                      >Note: File Type: jpg, jpeg, png, pdf, Max Size:
                      1MB</small
                    >
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-12 col-xs-12 pl-4 pr-4">
                  <div class="form-group">
                    <label><span class="form-items">Message</span></label>
                    <textarea
                      class="form-control from-control-custom"
                      placeholder="Enter Your Message"
                      rows="3"
                      v-model="donor_message"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-12 text-center d-flex justify-content-center">
              <button class="btn forget-btn">SEND</button>
            </div>
          </div>
        </form>

        <!-- <button @click="recaptcha">Recaptcha</button> -->
      </div>
    </div>

    <!-- donation clearification form area end -->
  </div>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";
const apiResourceUrl = process.env.VUE_APP_APIURL;
export default {
  name: "DonationClarification",
  data() {
    return {
      accounts: {},
      loaded_account: {},
      currency_lists: {},
      payment_methods: {},
      sectors: {},
      payment_method_id: "",
      amount: "",
      currency_id: "",
      donor_email: "",
      donor_name: "",
      date: "",
      sector_id: "",
      payer_account_no: "",
      donor_message: "",
      payee_account_no: "",
      attachment: null,
      account_div_seen: true,
      payer_account_div_seen: true,
      l_payer_account_no: "Donor's Bkash No *",
      l_payee_account_no: "Recipient Bkash No *",
    };
  },
  computed: {},
  methods: {
    filterPayeeAccountNo() {
      //console.log(this.payment_method_id)
      //console.log(this.accounts)
      this.account_div_seen = true;
      if (this.payment_method_id < 4) {
        this.account_div_seen = false;
      }

      this.payer_account_div_seen = true;
      if (this.payment_method_id == 8) {
        this.payer_account_div_seen = false;
      }

      this.loaded_account.splice(0);
      this.payee_account_no = "";
      for (var prop in this.accounts) {
        if (this.payment_method_id == 4) {
          this.l_payer_account_no = "Donor's Rocket No";
          this.l_payee_account_no = "Recipient's Rocket No *";
          if (this.accounts[prop]["type"] == "Rocket") {
            this.loaded_account.push(this.accounts[prop]);
            if (this.loaded_account.length == 1) {
              this.payee_account_no = this.accounts[prop]["name"];
            }
          }
        }

        if (this.payment_method_id == 5) {
          this.l_payer_account_no = "Donor's Bkash No";
          this.l_payee_account_no = "Recipient's Bkash No *";
          if (this.accounts[prop]["type"] == "Bkash") {
            this.loaded_account.push(this.accounts[prop]);
            if (this.loaded_account.length == 1) {
              this.payee_account_no = this.accounts[prop]["name"];
            }
          }
        }

        if (this.payment_method_id == 6) {
          this.l_payer_account_no = "Donor's Paypal Account No";
          this.l_payee_account_no = "Recipient's Paypal Account No *";
          if (this.accounts[prop]["type"] == "Paypal") {
            this.loaded_account.push(this.accounts[prop]);
            if (this.loaded_account.length == 1) {
              this.payee_account_no = this.accounts[prop]["name"];
            }
          }
        }

        if (this.payment_method_id == 7) {
          this.l_payer_account_no = "Donor's Bank Account No";
          this.l_payee_account_no = "Recipient's Bank Account No *";
          if (this.accounts[prop]["type"] == "Bank") {
            this.loaded_account.push(this.accounts[prop]);
            if (this.loaded_account.length == 1) {
              this.payee_account_no = this.accounts[prop]["name"];
            }
          }
        }

        if (this.payment_method_id == 8) {
          this.l_payer_account_no = "Donor's Account No";
          this.l_payee_account_no = "Recipient's Bank Account No *";
          if (this.accounts[prop]["type"] == "Bank") {
            this.loaded_account.push(this.accounts[prop]);
            if (this.loaded_account.length == 1) {
              this.payee_account_no = this.accounts[prop]["name"];
            }
          }
        }
      }
    },

    formSubmit(e) {
      e.preventDefault();
      var formObject = this;

      const form = new FormData();
      form.append("amount", this.amount);
      form.append("currency_id", this.currency_id);
      form.append("donor_email", this.donor_email);
      form.append("date", this.date);
      form.append("sector_id", this.sector_id);
      form.append("payment_method_id", this.payment_method_id);
      form.append("payee_account_no", this.payee_account_no);
      form.append("payer_account_no", this.payer_account_no);
      form.append("donor_name", this.donor_name);
      form.append("donor_message", this.donor_message);
      form.append("attachment", this.attachment);

      var config = {
        headers: { "Content-Type": "multipart/form-data" },
      };

      axios
        .post(apiResourceUrl + "/store-donation-clarification", form, config)
        .then(function(response) {
          //console.log(response.data.message);
          swal(response.data.message, "", "success");
          formObject.resetForm();
        })
        .catch(function(error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          //console.log(alertmessage)
          //alert(alertmessage)
          swal(alertmessage, "", "error");
        });
    },

    resetForm() {
      this.amount = "";
      this.currency_id = 2;
      this.donor_email = "";
      this.date = "";
      this.sector_id = 38;
      this.payment_method_id = 5;
      this.payee_account_no = "";
      this.payer_account_no = "";
      this.donor_name = "";
      this.donor_message = "";
      const input = this.$refs.file;
      input.type = "text";
      input.type = "file";

      this.filterPayeeAccountNo();
    },

    onFileSelected(event) {
      //console.log(event.target.files[0]);
      this.attachment = event.target.files[0];
    },

    // async recaptcha() {
    //   await this.$recaptchaLoaded();
    //   this.$recaptcha('login').then((token) => {
    //     console.log(token)
    //   })
    // }
  },
  async created() {
    await axios
      .get(apiResourceUrl + "/donation-clarification")
      .then((response) => {
        this.currency_lists = response.data.result.currency_lists;
        this.sectors = response.data.result.sectors;
        this.payment_methods = response.data.result.payment_methods;
        this.accounts = response.data.result.accounts;
        this.payment_method_id = 5;
        this.currency_id = 2;
        this.sector_id = 38;
        this.payee_account_no = "";
        this.loaded_account = [];

        for (var prop in this.accounts) {
          if (this.accounts[prop]["type"] == "Bkash") {
            this.loaded_account.push(this.accounts[prop]);
            if (this.loaded_account.length == 1) {
              this.payee_account_no = this.accounts[prop]["name"];
            }
          }
        }

        //console.log(this.loaded_account);
        //console.log(this.accounts);
      });
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.swal-title {
  margin: 0px;
  font-size: 10px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.21);
  margin-bottom: 28px;
}
.select_area a {
  border-radius: 7px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
</style>
