<template>
  <div>
    <!-- bank info header area start -->
        <div class="page_title_bg bank_title_section">
            <div class="container">
            <div class="row">
                <div class="col-xl-12 col-xs-12 donation_clearification_header text-center">
                    <p class="donation_clearification_title">Banks Informations</p>
                    <p class="white-bar"></p>
                    <p class="donation_clearification_text">Home / Bank Info</p>
                </div>
            </div>
            </div>
        </div>
        <!-- bank info header area end -->

        <!-- bank info body start -->
        <div class="container">
            <div class="row">
                <div class="col-xl-12 col-12 text-center">
                    <p class="bank_info_body_title">Payment With Bank</p>
                    <p class="grey-bar"></p>
                </div>

                <div class="col-xl-6 col-12">
                    <div class="bank_info_card_area bank_info_brac_bank text-center">
                        <img src="/assets/images/bank_icons/city_bank.webp" alt="brac">
                        
                        <div class="bank_title">CITY BANK</div>
                        <div class="bank_info_account_name">A/C NAME : Bidyanondo Foundation</div>
                        <div class="bank_info_account_no">A/C NO : 1103004604001</div>
                        <div class="bank_info_details">SWIFT CODE : CIBLBDDH</div>
                        <div class="bank_info_details">ROUTING NO : 225150135</div>
                        <div class="bank_info_details_branches">BRANCHES : Agrabad, Chittagong</div>

                    </div>
                </div>

                <div class="col-xl-6 col-12">
                    <div class="bank_info_card_area bank_info_brac_bank text-center">
                        <img src="/assets/images/bank_icons/brac_bank.webp" alt="brac">
                        
                        <div class="bank_title">BRAC BANK</div>
                        <div class="bank_info_account_name">A/C NAME : Bidyanondo Foundation</div>
                        <div class="bank_info_account_no">A/C NO : 1545203874746001</div>
                        <div class="bank_info_details">SWIFT CODE :  BRAKBDDH</div>
                        <div class="bank_info_details">ROUTING NO : 060274289</div>
                        <div class="bank_info_details_branches">BRANCHES : Shantinagar Branch</div>

                    </div>
                </div>

                <div class="col-xl-6 col-12">
                    <div class="bank_info_card_area text-center">
                        <img src="/assets/images/bank_icons/sibl.webp" alt="sibl">
                        <div class="bank_title">SOCIAL ISLAMIC BANK LTD</div>
                        <div class="bank_info_account_name">A/C NAME : Bidyanondo Foundation</div>
                        <div class="bank_info_account_no">A/C No : 0221330016199</div>
                        <div class="bank_info_details">SWIFT CODE : SOIVBDDH</div>
                        <div class="bank_info_details">ROUTING NO : 195274754</div>
                        <div class="bank_info_details_branches">BRANCHES : Nawabpur Branch, Dhaka</div>

                    </div>
                </div>

                <div class="col-xl-6 col-12">
                    <div class="bank_info_card_area text-center">
                        <img src="/assets/images/bank_icons/dbbl.webp" alt="dbbl">
                        
                        <div class="bank_title">Dutch-Bangla BANK LTD</div>
                        <div class="bank_info_account_name">A/C NAME : Bidyanondo Foundation</div>
                        <div class="bank_info_account_no">A/C NO : 1061200012479</div>
                        <div class="bank_info_details">SWIFT CODE :</div>
                        <div class="bank_info_details">ROUTING NO : 090671186</div>
                        <div class="bank_info_details_branches">BRANCHES : Narayangonj Branch, Narayangonj</div>

                    </div>
                </div>

            </div>
            
        </div>


            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="bank_info_mobile_bankikg_title_area text-center">
                            <p class="bank_info_mobile_banking_title">PAYMENT WITH MOBILE BANKING</p>
                            <p class="grey-bar"></p>
                        </div>
                    </div>
                </div>
            </div>
          
            <div class="bank_info_mobile_banking_bg_grey1">
                <div class="container">
                    <div class="row">
                        <div class="offset-2 col-xl-3 col-12 mobile_banking_logo">
                            <img class=" bkash_logo" src="/assets/images/mobile_banking/bKash.webp" alt="bkash">
                        </div>

                        <div class="col-xl-2 text-center">  
                            <p class="bkash_vartical_bar bank_vertical"></p>
                       </div>

                        <div class="col-xl-4 col-12">
                            <div class="mobile_banking_discription">
                                <div class="bkash_title">Bkash</div>
                                <div class="bkash_account_type">A/C TYPE : Merchant</div>
                                <div class="bkash_account_no">A/C NO : <span class="bkash_number">01878116230</span></div>
                                <div class="bkash_payment_option">PAYMENT OPTION PIN : 1</div>
                            </div>

                        </div>

                        <div class="col-xl-12 col-12 text-center">
                            <div class="bkash_personal_account_info">A/C TYPE : Personal <br>
                                A/C NO : <span class="bkash_number">01708521955, 01708521956</span>
                                <br><br>
                                <span>* You can also send money from Bkash APP</span>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>

            <div class="bank_info_mobile_banking_bg_white2">
                <div class="container">
                    <div class="row">
                        <div class="offset-2 col-xl-3 col-12">
                            <img class="mobile_banking_logo" src="/assets/images/mobile_banking/rocket.webp" alt="rocket">
                        </div>

                        <div class="col-xl-2">  
                            <p class="rocket_vartical_bar bank_vertical"></p>
                       </div>

                        <div class="col-xl-5 col-12">
                            <div class="mobile_banking_discription">
                                <div class="rocket_title">Rocket(dbbl)</div>
                                <div class="rocket_account_type">A/C TYPE : Personal</div>
                                <div class="rocket_account_no">A/C NO : <span class="rocket_number">017141180737</span>
                                </div>
                                <div class="bkash_payment_option">Merchant (Biller ID) : 151</div>
                            </div>
                          
                        </div>
                    </div>
                </div>
            </div>

            <div class="bank_info_mobile_banking_bg_grey3">
                <div class="container">
                    <div class="row">
                        <div class="offset-2 col-xl-3 col-12">
                            <img class="mobile_banking_logo" src="/assets/images/mobile_banking/nagad.webp" alt="rocket">
                        </div>
                        <div class="col-xl-2">  
                             <p class="nagad_vartical_bar bank_vertical"></p>
                        </div>

                        <div class="col-xl-5 col-12">
                            <div class="mobile_banking_discription">
                                <div class="nagad_title">nagad</div>
                                <div class="nagad_account_type">A/C TYPE : marchant</div>
                                <div class="nagad_account_no">A/C NO : <span class="nagad_number">01631554646</span> </div>
                                <div class="nagad_account_type">COUNTER NO : 1</div>
                            </div>
                         
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="container pb-3">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="text-center">
                            <p class="bank_info_mobile_banking_title">Paypal Account</p>
                            <p class="grey-bar"></p>
                        </div>
                    </div>
                    <div class="offset-2 col-xl-3 col-12 mobile_banking_logo">
                        <img class="" src="/assets/images/online_payment_icons/paypal.webp" alt="paypal">
                    </div>

                    <div class="col-xl-2">  
                         <p class="paypal_vartical_bar bank_vertical"></p>
                    </div>

                    <div class="col-xl-5 col-12 paypal-mobile">
                        <div class="mobile_banking_discription ">
                            <div class="paypal_title">PAYPAL</div>
                            <div class="paypal_account_type"> Account: <span class="paypal_email">donate@bidyanondo.org</span> 
                            </div>
                            <br><br><br><br>
                         </div>
                    </div>
                </div>
            </div>

            <div class="bank_info_mobile_banking_bg_grey3">
                <div class="container">
                    <div class="row">
                        <div class="offset-2 col-xl-3 col-12">
                            <img class="mobile_banking_logo" src="/assets/images/mobile_banking/tap.png" alt="rocket">
                        </div>
                        <div class="col-xl-2">  
                             <p class="nagad_vartical_bar bank_vertical"></p>
                        </div>

                        <div class="col-xl-5 col-12">
                            <div class="mobile_banking_discription">
                                <div class="nagad_title" style="color: #ec1c24;">Tap</div>
                                <div class="nagad_account_type">A/C TYPE : Merchant</div>
                                <div class="nagad_account_no">A/C NO : <span class="nagad_number" style="color: #ec1c24;">01766685686</span> </div>
                            </div>
                         
                        </div>
                    </div>
                </div>
            </div>

            <div class="container pb-3">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="text-center">
                            <br><br>
                        </div>
                    </div>
                    <div class="offset-2 col-xl-3 col-12 mobile_banking_logo">
                        <img class="" src="/assets/images/mobile_banking/upay.png" alt="paypal">
                    </div>

                    <div class="col-xl-2">  
                         <p class="paypal_vartical_bar bank_vertical"></p>
                    </div>

                    <div class="col-xl-5 col-12 paypal-mobile">
                        <div class="mobile_banking_discription ">
                            <div class="nagad_title" style="color:#1c52a5">Upay</div>
                            <div class="nagad_account_type"> A/C Type: <span>Merchant</span>
                            </div>
                            <div class="paypal_account_type"> A/C NO: <span class="nagad_number" style="color:#1c52a5">01878116232</span> 
                            </div>
                            <br><br>
                         </div>
                    </div>
                </div>
            </div>
         
 
        <!-- bank info body end -->
  </div>
</template>

<script>
  export default {
    name: 'BankInfo'
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
