import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
//import { VueReCaptcha } from 'vue-recaptcha-v3'
import { VueFbCustomerChat } from 'vue-fb-customer-chat'
import Toaster from "@meforma/vue-toaster";

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCreditCard, faAngleDoubleRight, faAngleDoubleLeft, faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { faPaypal } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
//createApp(App).use(router).use(store).mount('#app')

library.add(faCreditCard, faAngleDoubleRight, faAngleDoubleLeft, faAngleRight, faAngleLeft, faPaypal)

const app = createApp(App)
app.use(router)
app.use(store)
app.use(VueAxios, axios)
// app.use(VueReCaptcha, {
//   siteKey: process.env.VUE_APP_RECAPCHASITEKEY,
//   loaderOptions: {
//     useRecaptchaNet: true
//   }
// })
app.use(VueFbCustomerChat, {
  page_id: process.env.VUE_APP_FBPAGEID,
  theme_color: '#ed3237',
  locale: 'en_US',
})
app.use(Toaster, {
    // queue: true,
    // duration: 1500,
})
app.mount('#app')
app.component('font-awesome-icon', FontAwesomeIcon)
app.config.devtools = true
app.config.productionTip = true

